import { equal, handleAxiosError, like, nested, notEqual } from '@/api/helpers';
import {
  ChangeEnrollDateFormValues,
  CourseEntityScheduleEnum,
  CourseSchedule,
  CourseScheduleType,
  DeleteScheduleRequest,
  mapScheduleEntityEnumToEnrollEnum,
  mixpanelEntity,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useCourse,
  useCourseSchedules,
  useDeleteEnrollment,
  useDeleteSchedule,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  calculateDayDifferenceFromToday,
  coursesEnrollToOptions,
  getScheduleEntity,
} from '@/utils/helpers';
import { displayMiltipleItems } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CourseSchedulePage: React.FC<{ isMaterial?: boolean }> = ({
  isMaterial,
}) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();
  const { setDialogData } = useContext(DialogContext);
  const { track } = useMixpanel();
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const { cannot } = usePermission();
  const isBranchAdmin = branchAdminCheck(user, account);
  const { canUseFeature } = useFeatureFlag();

  const {
    course,
    isLoading: isCourseLoading,
    isRefetching: isCourseRefetching,
    refetch: courseRefetch,
  } = useCourse({
    courseId: courseId,
  });

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [selectedSchedule, setSelectedSchedule] = useState<
    CourseSchedule | undefined
  >(undefined);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const {
    isLoading: isScheduleLoading,
    schedules,
    refetch,
  } = useCourseSchedules(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length
          ? [nested('entity', [like('name', multiSearchValue)])]
          : []),
        ...(account?.isSystem
          ? []
          : [nested('account', [equal('id', account?.id)])]),
        ...(cannot(Actions.READ, Subjects.COURSE_GROUP_SCHEDULES)
          ? [notEqual('source', CourseEntityScheduleEnum.GROUP)]
          : []),
      ],
      sort:
        sort && sort.length > 0 && sort[0] !== 'created'
          ? [sort.join(',')]
          : ['date,asc'],
      enabled: true,
    },
    courseId,
  );

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedSchedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedSchedule?.id,
          scheduleType: selectedSchedule?.entity.type,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
          // Track Mixpanel course enrollment with type update
          track('Update course enrollment', {
            Entity:
              selectedSchedule.entity.type === CourseEntityScheduleEnum.ACCOUNT
                ? 'Account'
                : selectedSchedule.entity.type ===
                  CourseEntityScheduleEnum.BRANCH
                ? 'Branch'
                : 'User',
            'Course id': course?.id,
            'Course name': course?.name,
            'Old enroll date (UTC)': moment
              .utc(selectedSchedule?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'New enroll date (UTC)': moment
              .utc(data?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enrolled to':
              selectedSchedule.entity.type === CourseEntityScheduleEnum.USER
                ? Md5.hashStr(selectedSchedule?.entity?.id)
                : selectedSchedule?.entity?.name,
            'Account name': account?.name,
            'Total number of days': calculateDayDifferenceFromToday(data?.date),
            'Current date (UTC)': moment
              .utc()
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enroll type': selectedSchedule?.autoEnroll ? 'auto' : 'manual',
          });
        }

        await refetch();

        setSelectedSchedule(undefined);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteSchedule = useDeleteSchedule();
  const handleDeleteSchedule = async (payload: DeleteScheduleRequest) => {
    try {
      await deleteSchedule.delete(payload);

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity: mixpanelEntity[payload.source],
          'Course id': course?.id,
          'Course name': course?.name,
          'Enroll date (UTC)': moment
            .utc(payload?.entity?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to':
            payload.entity.type === CourseEntityScheduleEnum.USER
              ? Md5.hashStr(payload?.entity?.entity?.id)
              : payload?.entity?.entity?.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': payload?.entity?.autoEnroll ? 'auto' : 'manual',
        });
      }

      await refetch();
      toast?.success(
        t('toast.success'),
        t(`courses.${payload.entity.type}.unenroll.success`),
      );
      await courseRefetch();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const deleteEnrollment = useDeleteEnrollment();
  const handleDeleteEnrollment = async (
    courseId: string,
    courseSchedule: CourseSchedule,
  ) => {
    try {
      await deleteEnrollment.post({
        courseId,
        request: {
          [mapScheduleEntityEnumToEnrollEnum[courseSchedule.entity.type]]: [
            courseSchedule.entity.id,
          ],
        },
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity:
            courseSchedule.entity.type === CourseEntityScheduleEnum.ACCOUNT
              ? 'Account'
              : courseSchedule.entity.type === CourseEntityScheduleEnum.BRANCH
              ? 'Branch'
              : 'User',
          'Course id': course?.id,
          'Course name': course?.name,
          'Enroll date (UTC)': moment
            .utc(courseSchedule?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to':
            courseSchedule.entity.type === CourseEntityScheduleEnum.USER
              ? Md5.hashStr(courseSchedule?.entity?.id)
              : courseSchedule?.entity?.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': courseSchedule?.autoEnroll ? 'auto' : 'manual',
        });
      }

      await refetch();
      toast?.success(
        t('toast.success'),
        t(`courses.${courseSchedule.entity.type}.unenroll.success`),
      );

      setSelectedSchedule(undefined);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const changeEnrollMethod = useChangeScheduleEnrollType();
  const handleChangeEnrollMethod = async (
    courseSchedule: CourseSchedule | undefined,
  ) => {
    if (courseSchedule?.id) {
      try {
        await changeEnrollMethod.edit({
          scheduleId: courseSchedule.id,
          scheduleType: courseSchedule.entity.type,
          autoEnroll: !courseSchedule.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const menuItems = (courseSchedule: CourseSchedule) => {
    const menu: MenuItem[] = [];

    if (courseSchedule.entity?.type !== CourseEntityScheduleEnum.USER) {
      menu.push({
        label: courseSchedule.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeEnrollMethod(courseSchedule);
            },
          }),
      });
    }

    if (!courseSchedule.executedAt) {
      menu.push(
        {
          label: t('courses.change.enrollDate'),
          icon: 'pi pi-calendar',
          command: () => setSelectedSchedule(courseSchedule),
        },
        {
          label: t('generic.deleteSchedule'),
          icon: 'pi pi-calendar-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.deleteSchedule'),
              message: t(
                `dialog.deleteSchedule.${courseSchedule.entity.type}`,
                {
                  name: courseSchedule?.entity?.name,
                },
              ),
              onAccept: async () => {
                await handleDeleteSchedule({
                  entity: courseSchedule,
                  id: courseSchedule.id,
                  source: courseSchedule.entity.type,
                });
              },
            }),
        },
      );
    }

    if (courseSchedule?.executedAt && !courseSchedule.unEnrolledAt) {
      menu.push({
        label: t('generic.unenroll'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.deleteEnrollment'),
            message: t(
              `dialog.deleteEnrollment.${courseSchedule.entity.type}`,
              {
                name: courseSchedule.entity?.name,
              },
            ),
            onAccept: async () => {
              if (!courseId) return;
              await handleDeleteEnrollment(courseId, courseSchedule);
            },
          }),
      });
    }
    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'account.name',
      className: 'vertical-top',
      header: t('account'),
      sortable: false,
      filterable: false,
      style: {
        minWidth: '200px',
      },
      render: (row: CourseSchedule) => row?.account?.name,
    },
    {
      field: 'entity.name',
      header: t('generic.enrollingTo'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.entity?.id ? (
          displayMiltipleItems(row.entity?.name, t)
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'entity.type',
      header: t('generic.entity'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.ENTITY_TYPE,
        type: FilterTypeEnum.MULTI_SELECT,
        options: coursesEnrollToOptions(t, user, account, true),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.entity?.type ? (
          getScheduleEntity(row.entity.type)
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.SCHEDULE_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.date ? (
          <FormatDate format={DateFormats.TIMEDATE} date={row.date} />
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'unEnrolledAt',
      header: t('generic.unEnrolledAt'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.unEnrolled'),
        field: FilterNamesEnum.SCHEDULE_UNENROLLED,
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('filter.unEnrolled'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.unEnrolledAt ? (
          <FormatDate format={DateFormats.TIMEDATE} date={row.unEnrolledAt} />
        ) : (
          <div>&#8212;</div>
        ),
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'dueDate',
            header: t('generic.dueDate'),
            sortable: true,
            filterable: false,
            className: 'no-padding',
            render: (row: CourseSchedule) =>
              row?.dueDate ? (
                <FormatDate format={DateFormats.TIMEDATE} date={row.dueDate} />
              ) : (
                <div>&#8212;</div>
              ),
          } as DataTableColumnType,
        ]
      : []),
    {
      field: 'usersCount',
      header: t('users'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.usersCount ? row.usersCount : <div>&#8212;</div>,
    },
    {
      field: 'scheduledBy.name',
      header: t('generic.scheduledBy'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.scheduledBy?.name.trim() ? (
          row.scheduledBy?.name
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'autoEnroll',
      header: t('generic.enrollType'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byEnrollType'),
        field: FilterNamesEnum.COURSE_SCHEDULE_AUTO_ENROLL,
        type: FilterTypeEnum.SELECT_AUTO_MANUAL,
        placeholder: t('filter.enrollType'),
      },
      className: 'no-padding',
      render: (row: CourseSchedule) =>
        row?.entity?.type ? (
          row?.entity.type === CourseEntityScheduleEnum.USER ? (
            <div>&#8212;</div>
          ) : row?.autoEnroll ? (
            <AppChip label={t('generic.auto')} type="primary" />
          ) : (
            <AppChip label={t('generic.manual')} type="secondary" />
          )
        ) : (
          <div>&#8212;</div>
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseSchedule) => (
        <DataTableActions
          menuItems={menuItems(row)}
          disabled={
            menuItems(row).length < 1 ||
            (row?.entity?.type === CourseEntityScheduleEnum.ACCOUNT &&
              isBranchAdmin)
          }
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={{ [FilterNamesEnum.SCHEDULE_UPCOMING_DATE]: 'yes' }}
          className="flex-initial"
          tableName={
            isMaterial
              ? TableNamesEnum.MATERIAL_SCHEDULE
              : TableNamesEnum.COURSE_SCHEDULE
          }
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={t('courses.schedule.search')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_SCHEDULE
            : TableNamesEnum.COURSE_SCHEDULE
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>
            {isMaterial
              ? t('material.schedule.loading')
              : t('course.schedule.loading')}
          </h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && <CoursesTabs courseId={courseId} type={course.type} />}
          <DataTable
            dataKey="dataId"
            rowGroupMode="rowspan"
            groupRowsBy="account.name"
            data={schedules?.result.map((x, i) => ({
              ...x,
              dataId: `${x.entity?.id}-${x.course?.id}-${i}`,
            }))}
            count={schedules?.count as number}
            isLoading={isScheduleLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
            classNames="rowspan-datatable with-actions"
          />
          <ChangeEnrollDateModal
            date={selectedSchedule?.date}
            visible={!!selectedSchedule}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedSchedule(undefined)}
          />
        </>
      )}
    </>
  );
};
