import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { AccountFeatureFlag } from '@/client/feature-flags/types';
import { TableNamesEnum } from '@/common/constants';
import { roleTranslations } from '@/common/constants/roles';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useAccountsFeatureFlags } from '@/hooks/query/feature-flags.hooks';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FeatureFlagsModal } from '../modals/FeatureFlagsModal';

export const AccountFeatureFlagsDatatable: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id: accountId } = useParams();

  const [isFeatureModalVisible, setIsFeatureModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<AccountFeatureFlag>();

  const { apiFilters } = useTable();
  const { isLoading, flags, refetch } = useAccountsFeatureFlags(accountId, {
    filters: apiFilters,
  });

  const handleUpdateFeatureFlag = async (roles: string[]) => {
    if (!accountId) return;

    try {
      setIsSubmitting(true);

      await client.featureFlags.updateAccountFeatureFlags({
        accountId,
        featureFlags: flags?.map((flag: AccountFeatureFlag) => {
          if (flag?.featureFlag?.id === selectedFeature?.featureFlag?.id) {
            return {
              featureFlag: selectedFeature?.featureFlag?.id ?? '',
              active: !!roles?.length,
              roles,
            };
          }

          return {
            featureFlag: flag?.featureFlag?.id ?? '',
            active: !!flag?.roles?.length,
            roles: flag.roles?.map(({ id }) => id) ?? [],
          };
        }),
      });

      refetch();
      setIsFeatureModalVisible(false);

      toast?.success(t('toast.success'), t('feature.edit.success'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('feature.name'),
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) => (
        <span>{t(`features.${row?.featureFlag?.key}`)}</span>
      ),
    },
    {
      field: 'featureFlag.key',
      header: t('feature.key'),
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) => <span>{row?.featureFlag?.key}</span>,
    },
    {
      field: 'roles',
      header: t('generic.role'),
      style: { width: '380px' },
      sortable: false,
      filterable: false,
      render: (row: AccountFeatureFlag) =>
        row?.roles?.length ? (
          row.roles?.map((role) => roleTranslations(role, t))?.join(', ')
        ) : (
          <div className="group-row">&#8212;</div>
        ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: AccountFeatureFlag) => (
        <DataTableActions menuItems={menuItems(row)} />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <div />
        <DataTableColumnsMultiselect
          columns={columns}
          tableName={TableNamesEnum.ACCOUNT_FEATURES_LIST}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          alwaysVisibleColumns={alwaysVisibleColumns}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  const menuItems = (feature: AccountFeatureFlag) => {
    const menu: MenuItem[] = [
      {
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () => {
          setSelectedFeature(feature);
          setIsFeatureModalVisible(true);
        },
      },
    ];

    return menu;
  };

  return (
    <>
      <DataTable
        data={flags}
        count={flags?.length}
        isLoading={isLoading}
        columns={columns}
        visibleColumns={visibleColumns}
        toolbar={toolbar}
      />
      <FeatureFlagsModal
        currentFeature={selectedFeature}
        visible={isFeatureModalVisible}
        isSubmitting={isSubmitting}
        isAccount
        onSubmit={handleUpdateFeatureFlag}
        onHide={() => setIsFeatureModalVisible(false)}
      />
    </>
  );
};
