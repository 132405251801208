import { Account } from '@/client/accounts';
import { User } from '@/client/users';
import { FlexContainer } from '@/ui/styled-ui';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountTabs } from './AccountTabs';

import {
  LAST_SELECTED_ACCOUNT_SETTINGS,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledToggleContainer = styled(FlexContainer)`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
  flex: 0 0 25%;
  min-width: max-content;
`;

type AccountHeaderPropsType = {
  selectedAccount: Account | undefined;
  isCreate?: boolean;
  setShowSystemSettings?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AccountHeader: React.FC<AccountHeaderPropsType> = ({
  selectedAccount,
  isCreate,
  setShowSystemSettings,
}) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();

  const lastSelected =
    sessionStorage.getItem(LAST_SELECTED_ACCOUNT_SETTINGS) === 'true';
  const [systemSettingsEnabled, setSystemSettingsEnabled] =
    useState(lastSelected);

  useEffect(() => {
    if (!setShowSystemSettings) return;
    setShowSystemSettings(systemSettingsEnabled);
  }, [systemSettingsEnabled]);

  const navigateToDetailsIfTabIsNotPresent = () => {
    if (
      ![
        RedirectPaths[RedirectPathsEnum.ACCOUNT](selectedAccount?.id as string),
        RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](
          selectedAccount?.id as string,
        ),
        RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](
          selectedAccount?.id as string,
        ),
      ].includes(location.pathname)
    ) {
      navigate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT](selectedAccount?.id as string),
      );
    }
  };

  return (
    <>
      <FlexContainer gap={40} justify="space-between">
        <h1 className="mt-4 mb-4">
          {selectedAccount?.name ?? currentAccount?.name}
        </h1>
        {!currentAccount?.isSystem && currentUser?.account.isSystem && (
          <StyledToggleContainer gap={12} align="center" justify="flex-end">
            <div>{t('account.systemSettings')}</div>
            <InputSwitch
              checked={systemSettingsEnabled}
              onChange={() => {
                setSystemSettingsEnabled(!systemSettingsEnabled);
                sessionStorage.setItem(
                  LAST_SELECTED_ACCOUNT_SETTINGS,
                  `${!systemSettingsEnabled}`,
                );
                navigateToDetailsIfTabIsNotPresent();
              }}
            />
          </StyledToggleContainer>
        )}
      </FlexContainer>
      {selectedAccount && (
        <AccountTabs
          account={selectedAccount}
          systemSettingsEnabled={systemSettingsEnabled}
        />
      )}
      {currentAccount && !selectedAccount && !isCreate && (
        <AccountTabs
          account={currentAccount}
          systemSettingsEnabled={systemSettingsEnabled}
        />
      )}
    </>
  );
};
