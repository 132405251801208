import { PosterCategory } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledTabMenu = styled(TabMenu)`
  &.p-tabmenu .p-tabmenu-nav {
    background-color: transparent;
  }
`;

export const PosterCategoriesTabs: React.FC = () => {
  const { t } = useTranslation();

  const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
    (
      <Link
        to={url}
        className={classNames({
          'p-menuitem-link': true,
          'p-menuitem-link-active': current.includes(url),
        })}
        role="menuitem"
      >
        <span className="p-menuitem-text">{item.label}</span>
      </Link>
    );

  const tabItems: MenuItem[] = [
    {
      label: t('posters.all'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS](),
    },
    {
      label: t(`posters.${PosterCategory.COURSE_POSTERS}`),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS_COURSE](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS_COURSE](),
    },
    {
      label: t(`posters.${PosterCategory.CYBERSECURITY}`),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS_CYBERSECURITY](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS_CYBERSECURITY](),
    },
    {
      label: t(`posters.${PosterCategory.GDPR}`),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS_GDPR](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS_GDPR](),
    },
    {
      label: t(`posters.${PosterCategory.INFO_SCREEN}`),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS_INFO_SCREEN](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS_INFO_SCREEN](),
    },
    {
      label: t(`posters.${PosterCategory.INFOGRAPHICS}`),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.POSTERS_INFOGRAPHICS](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.POSTERS_INFOGRAPHICS](),
    },
  ];

  const paths = tabItems.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <StyledTabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
