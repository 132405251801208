import { Actions, Subjects } from '@/client/users';
import { DashboardType } from '@/common/constants';
import { ConfirmationDialog } from '@/common/context';
import { AppContext } from '@/common/context/AppContext';
import { SidebarMenu, TopbarMenu } from '@/components/navigation';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentDashboard } from '@/store/features/dashboard';
import { isOutsideOfEfront } from '@/utils/helpers';
import { isMobile } from '@/utils/helpers/ui.helper';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const MainLayout: React.FC = () => {
  const { can } = usePermission();
  const selectedDashboard = useAppSelector(selectCurrentDashboard);
  const { state } = useContext(AppContext);

  return (
    <Container
      className={
        isOutsideOfEfront()
          ? state.isFullScreen
            ? 'fullscreen'
            : 'with-topbar'
          : ''
      }
    >
      {isOutsideOfEfront() && !state.isFullScreen && (
        <TopMenu>
          <TopbarMenu />
        </TopMenu>
      )}
      {(isOutsideOfEfront() === false ||
        (can(Actions.READ, Subjects.ADMIN_DASHBOARD) &&
          selectedDashboard === DashboardType.ADMIN)) && (
        <SideMenu className="navigation">
          <SidebarMenu />
        </SideMenu>
      )}
      <Content
        className={[
          'content',
          isOutsideOfEfront() === false ||
          (can(Actions.READ, Subjects.ADMIN_DASHBOARD) &&
            selectedDashboard === DashboardType.ADMIN)
            ? ''
            : 'without-sidebar',
          isMobile() ? 'mobile' : '',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        <ConfirmationDialog>
          <Outlet />
        </ConfirmationDialog>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0 repeat(1, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  min-height: 800px;
  height: 100%;
  background-color: var(--purple-dark);

  > ::-webkit-scrollbar-track {
    margin: 16px 0 0 0;
  }

  .navigation {
    grid-area: 1 / 1 / 6 / 2;
    overflow-x: auto;
    &::-webkit-scrollbar-track {
      background-color: var(--purple-dark);
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid var(--purple-dark);
    }
    &::-webkit-scrollbar-corner {
      background-color: var(--purple-dark);
    }
  }
  .content {
    grid-area: 1 / 2 / 6 / 6;
    background-color: var(--white-main);
  }

  &.with-topbar {
    min-height: auto;
    grid-template-rows: var(--top-nav-height) repeat(2, 1fr);
    .navigation {
      grid-area: 2 / 1 / 6 / 2;
      padding: var(--small-padding) 0 var(--default-padding);
    }
    .content {
      grid-area: 2 / 2 / 6 / 6;
      border-radius: var(--default-border-radius) var(--default-border-radius) 0
        0;
      width: calc(100% - var(--default-padding));
    }
  }

  .content.without-sidebar {
    grid-area: 2 / 1 / 6 / 6 !important;
    max-width: var(--max-dashboard-width);
    margin: 0 auto;
    width: calc(100% - calc(var(--big-padding) * 2));

    @media only screen and (max-device-width: 812px) {
      width: calc(100% - calc(var(--medium-padding) * 2)) !important;
    }

    &.mobile {
      width: 100% !important;
      border-radius: 0;
      padding: var(--medium-padding);
    }
  }

  &.fullscreen {
    min-height: auto;
    display: flex;

    .content.without-sidebar {
      display: flex;
      width: 100%;
      padding: 0 var(--small-padding);
      overflow: initial;

      @media only screen and (max-device-width: 812px) {
        width: 100% !important;
      }
    }
  }
`;

const TopMenu = styled.div`
  width: 100%;
  grid-area: 1 / 1 / 1 / 6;
  background: var(--purple-dark);
`;

const Content = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding: var(--default-padding);

  &:has(.without-padding) {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
`;

const SideMenu = styled.div`
  padding: var(--default-padding) 0;
  height: 100%;
  background-color: var(--purple-dark);
`;

export default MainLayout;
