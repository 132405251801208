import { handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { UsersReportType } from '@/client/reports';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { ReportsExportModal } from '@/components/reports/ReportsExportModal';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: var(--default-padding);

  h1 {
    margin: 0;
  }
`;

type ReportsActionHeaderProps = {
  type: UsersReportType;
  visibleColumns: string[];
  filters?: FiltersType | undefined;
  sort?: [string, 'asc' | 'desc'] | null;
  setForceRefetch?: Dispatch<boolean>;
};

const getBreadcrumbs = (
  account: Account,
  type: string,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  switch (type) {
    case UsersReportType.SUMMARY: {
      items.push({
        label: t('reports.summary'),
        url: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        template: AppBreadCrumbTemplate,
      });
      break;
    }
    case UsersReportType.AWARENESS: {
      items.push({
        label: t('reports.awareness'),
        url: RedirectPaths[RedirectPathsEnum.REPORTS_AWARENESS](),
        template: AppBreadCrumbTemplate,
      });
      break;
    }
    default:
      break;
  }

  return items;
};

export const ReportsActionHeader: React.FC<ReportsActionHeaderProps> = ({
  type,
  visibleColumns,
  filters,
  sort,
  setForceRefetch,
}) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [showExportModal, setShowExportModal] = useState(false);

  useEffect(() => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({ [HubspotProperty.REPORTS_OPENED]: HubspotValue.YES });
    }
  }, []);

  const [isRebuildingData, setIsRebuildingData] = useState<boolean>(false);
  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: accountId,
    isConnected,
  } = useNotifications(client.reports.reportsNotifyUrl);

  const handleRebuildSummaryReport = async () => {
    if (account) {
      setAccountId(account.id);
      setIsRebuildingData(true);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected.current) {
          await client.reports.rebuildSummaryReport(account.id);
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
        setAccountId(undefined);
        setIsRebuildingData(false);
      }
    }
  };

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      !['account.summary-report.generated'].includes(messages?.data?.event)
    )
      return;

    if (messages.data.event === 'account.summary-report.generated') {
      if (messages.data.success) {
        toast?.success(
          t('toast.success'),
          t('reports.summary.rebuild.success'),
        );

        if (setForceRefetch) {
          setForceRefetch(true);
          refetchAccount();

          setTimeout(() => {
            setForceRefetch(false);
          });
        }
      } else {
        toast?.success(t('toast.error'), messages.data.error);
      }
    }

    setAccountId(undefined);
    setIsRebuildingData(false);
  };

  const refetchAccount = async () => {
    if (account) {
      try {
        const response = await client.accounts.getAccount(account.id);
        dispatch(setCurrentAccount(response));
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, type, t)} />

      <StyledFlexContainer justify="space-between">
        <FlexContainer align="flex-start" direction="column">
          <FlexContainer align="center" justify="flex-start">
            <h1 className="mb-0">{t(`reports.${type}`)}</h1>
            {type === UsersReportType.SUMMARY && (
              <AppButton
                icon="pi pi-refresh"
                size="sm"
                type="text"
                className="ml-1"
                ariaLabel={t('reports.summary.rebuild')}
                tooltip={t('reports.summary.rebuild')}
                tooltipOptions={{ position: 'top' }}
                isDisabled={isRebuildingData}
                state={
                  isRebuildingData
                    ? LoadingStatuses.LOADING
                    : LoadingStatuses.IDLE
                }
                onClick={handleRebuildSummaryReport}
              />
            )}
          </FlexContainer>
          {account?.meta?.reportsLastGeneratedAt &&
            type === UsersReportType.SUMMARY && (
              <span className="secondary-text">
                {t('reports.lastUpdatedOn', {
                  date: moment(account.meta.reportsLastGeneratedAt).format(
                    DateFormats.TIMEDATE,
                  ),
                })}
              </span>
            )}
        </FlexContainer>

        <FlexContainer gap={12} justify="flex-end">
          <AppButton
            icon="pi pi-download"
            label={t('generic.export')}
            onClick={() => setShowExportModal(true)}
          />
        </FlexContainer>

        <ReportsExportModal
          type={type}
          visibleColumns={visibleColumns}
          filters={filters}
          sort={sort}
          visible={showExportModal}
          onHide={() => setShowExportModal(false)}
        />
      </StyledFlexContainer>
    </>
  );
};
