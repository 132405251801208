import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLearner } from '../context';

const getTabItems = (
  hasResources: boolean,
  hasCustomMaterials: boolean,
  t: TranslationFunctionType,
) => {
  const items: MenuItem[] = [];
  if (hasCustomMaterials) {
    items.push(
      {
        label: t('generic.toDo'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO](),
          location.pathname,
        ),
        data: RedirectPaths[
          RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO
        ](),
      },
      {
        label: t('generic.completed'),
        template: getItemTemplate(
          RedirectPaths[
            RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_COMPLETED
          ](),
          location.pathname,
        ),
        data: RedirectPaths[
          RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_COMPLETED
        ](),
      },
    );
  }

  if (hasResources) {
    items.push({
      label: t('resources'),
      template: getItemTemplate(
        RedirectPaths[
          RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_RESOURCES
        ](),
        location.pathname,
      ),
      data: RedirectPaths[
        RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_RESOURCES
      ](),
    });
  }

  return items;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const LearnerDashboardMaterialsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { hasCustomMaterials, hasResources } = useLearner();

  const tabItems = getTabItems(hasResources, hasCustomMaterials, t);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    tabItems.findIndex(({ data }: MenuItem) =>
      data?.urls
        ? data.urls.some((path: string) => path === currentPath)
        : data === currentPath,
    ),
  );

  return (
    <TabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
