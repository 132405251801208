import { Course, CourseStatusAttemptEnum } from '@/client/courses';
import { User } from '@/client/users';

export const enum HubspotProperty {
  ID = 'id',
  EMAIL = 'email',
  COMPANY = 'company',
  USER_LOGIN = 'user_login',
  ADMIN_DASHBOARD_OPENED = 'admin_dashboard_opened',
  REPORTS_OPENED = 'reports_view',
  PHISHING_CAMPAIGN_VIEW = 'phishing_campaign_view',
  NEW_USER_CREATED = 'new_user__created',
  NEW_USER_ENROLLED_TO_A_COURSE = 'new_user_enrolled_to_a_course',
  VISITED_SECURITY_CULTURE = 'visited_security_culture__cp_app_',
}

export const enum HubspotValue {
  YES = 'Yes',
}

type HubspotTrackProps = {
  [key in HubspotProperty]?: HubspotValue | string | number;
};

export const hubspotTrack = (props: HubspotTrackProps) => {
  const _hsq =
    typeof window !== 'undefined' && (window as any)._hsq
      ? (window as any)._hsq
      : [];

  _hsq.push([
    'identify',
    {
      ...props,
    },
  ]);
  _hsq.push(['trackPageView']);
};

export const revokeHubspotCookies = () => {
  const _hsq =
    typeof window !== 'undefined' && (window as any)._hsq
      ? (window as any)._hsq
      : [];

  _hsq.push(['revokeCookieConsent']);
};

export const hubspotTrackCourseCompletion = (
  course: Course,
  status: CourseStatusAttemptEnum,
) => {
  const hubspotProperty = course?.meta?.hubspotProperty;

  if (status === CourseStatusAttemptEnum.COMPLETED && !!hubspotProperty) {
    hubspotTrack({
      [hubspotProperty as HubspotProperty]: HubspotValue.YES,
    });
  }
};

export const hubspotTrackMaterialDownloaded = (course: Course) => {
  const hubspotProperty = course?.meta?.hubspotProperty;

  hubspotTrack({
    [hubspotProperty as HubspotProperty]: HubspotValue.YES,
  });
};

export const identifyHubspotContact = (user: User) => {
  hubspotTrack({
    [HubspotProperty.ID]: user.id,
    [HubspotProperty.EMAIL]: user.email,
    [HubspotProperty.COMPANY]: user?.account?.name,
    [HubspotProperty.USER_LOGIN]: HubspotValue.YES,
  });
};
