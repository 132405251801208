import {
  AppRoutesPaths,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { useAppDispatch } from '@/hooks/store';
import UnauthorizedImage from '@/images/placeholders/unauthorized.svg';
import { removeCurrentUserAction } from '@/store/features/users';
import { FlexContainer } from '@/ui/styled-ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledImg = styled.img`
  height: auto;
  width: 100%;
`;

const StyledFlexContainer = styled(FlexContainer)`
  border-radius: var(--medium-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
  overflow-x: hidden;

  h1 {
    font-size: var(--heading-large-font-size);
    line-height: var(--heading-large-line-height);
    text-align: center;
    margin-top: var(--default-padding);
  }

  p {
    text-align: center;
    line-height: var(--medium-line-height);
    font-weight: 500;
    text-align: center;
    margin: 0;
  }
`;

const StyledPath = styled(NavLink)`
  text-decoration: none;
  color: var(--red-main);

  &:hover {
    text-decoration: underline;
  }
`;

type AppUnauthorizedProps = {
  text?: string;
};

export const AppUnauthorized: React.FC<AppUnauthorizedProps> = ({ text }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Clear the session storage
    sessionStorage.clear();
    removeCurrentUserAction(dispatch);

    // Redirect to login page after few seconds (3 seconds in this case)
    const timer = setTimeout(() => {
      navigate(RedirectPaths[RedirectPathsEnum.LOGOUT]());
    }, 3000);

    // Cleanup function
    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledFlexContainer height="100%" direction="column">
      <StyledImg
        style={{ maxHeight: '316px' }}
        src={UnauthorizedImage}
        alt={t('courses.noCourses.heading')}
      />
      <h1>{t('auth.unautherized')}</h1>
      <p>
        {text ?? t('auth.noPermission')}
        {/* TODO: find a better redirection that is applicable to every case in which we use unauthorized page. This will lead to better UX as you're not stuck in unauthorized page. */}
        {/* <br />
        {!text && (
          <StyledPath to={AppRoutesPaths.LOGOUT}>{t('auth.signIn')}</StyledPath>
        )} */}
      </p>
    </StyledFlexContainer>
  );
};
