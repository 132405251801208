import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import {
  AccountsEnrollFormValues,
  Course,
  CourseAvailabilityFormValues,
  CourseAvailabilityTypeEnum,
  CourseEnrollmentTypeEnum,
  CourseEntityEnrollEnum,
  CourseEntityScheduleEnum,
  DeleteEnrollmentFormValues,
  EnrollFormValues,
  MaterialType,
  mixpanelEntity,
} from '@/client/courses';
import { Group } from '@/client/groups';
import { Actions, Subjects, User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { LanguageContext } from '@/common/context/LanguageContext';
import { TranslationFunctionType } from '@/common/types';
import { CourseAccountsEnrollModal } from '@/components/courses/modals/AccountsEnrollModal';
import { CourseAvailabilityModal } from '@/components/courses/modals/CourseAvailabilityModal';
import { EnrollModal } from '@/components/courses/modals/EnrollModal';
import { DataTableActions } from '@/components/tables/crud';
import {
  useAccountsSchedules,
  useBranchesSchedules,
  useDeleteCourse,
  useDeleteEnrollment,
  useGroupsSchedules,
  useMakeAccountsAvailable,
  useMakeAccountsUnavailable,
  useUpdateCourse,
  useUsersSchedules,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, usePendo } from '@/hooks/usePendo';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  getTranslationByLanguage,
  materialByTypeListPath,
} from '@/utils/helpers';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { SyntheticEvent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';

type CoursesTabsProps = {
  course: Course;
  selfHosted?: boolean;
  onChangeCourseType?: (selfHosted: boolean) => void;
  onTriggerRefetch?: () => void;
};

const getBreadcrumbs = (
  account: Account,
  course: Course,
  language: LanguagesEnum,
  location: Location,
  t: TranslationFunctionType,
): MenuItem[] => {
  const isResource = course.type === MaterialType.RESOURCE;
  const isPoster = course.type === MaterialType.POSTER;
  const isCustomMaterial = course.type === MaterialType.CUSTOM_MATERIAL;
  const isMaterial = isResource || isCustomMaterial || isPoster;

  const items: MenuItem[] = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (isMaterial) {
    items.push({
      label: isResource
        ? t('resources')
        : isPoster
        ? t('posters')
        : t('custom.materials'),
      url: materialByTypeListPath(course.type),
      className: 'active',
      template: AppBreadCrumbTemplate,
    });
  } else {
    items.push({
      label: t('courses'),
      url: RedirectPaths[RedirectPathsEnum.COURSES](),
      template: AppBreadCrumbTemplate,
    });
  }

  items.push({
    label: getTranslationByLanguage(
      course?.courseTranslations,
      language as LanguagesEnum,
    )?.name,
    url: `${location?.pathname}${location?.search}`,
    template: AppBreadCrumbTemplate,
  });

  return items;
};

export const CoursesActionHeader: React.FC<CoursesTabsProps> = ({
  course,
  selfHosted,
  onChangeCourseType,
  onTriggerRefetch,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const { setDialogData } = useContext(DialogContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const retired = !!course.retired;
  const { pendoTrackEnrollment } = usePendo();
  const { track } = useMixpanel();
  const isResource = course.type === MaterialType.RESOURCE;
  const isCustomMaterial = course.type === MaterialType.CUSTOM_MATERIAL;
  const isPoster = course.type === MaterialType.POSTER;
  const isMaterial = isResource || isCustomMaterial || isPoster;
  const isBranchAdmin = branchAdminCheck(user, account);

  const saveAvailableAccounts = useMakeAccountsAvailable();
  const saveUnavailableAccounts = useMakeAccountsUnavailable();

  const [showMakeAvailableModal, setShowMakeAvailableModal] = useState(false);
  const [showMakeUnavailableModal, setShowMakeUnavailableModal] =
    useState(false);
  const [showCourseAccountsEnrollModal, setCourseAccountsEnrollModal] =
    useState(false);
  const [showEnrollModal, setEnrollModal] = useState(false);
  const [showUnenrollModal, setUnenrollModal] = useState(false);
  const [enrollModalType, setEnrollModalType] =
    useState<CourseEnrollmentTypeEnum>();
  const [enrollModalEntityType, setEnrollModalEntityType] =
    useState<CourseEntityScheduleEnum>();
  const [showDelete, setShowDelete] = useState<boolean>(
    !!(
      (course.draft || isMaterial) &&
      !isBranchAdmin &&
      can(Actions.DELETE, Subjects.COURSES)
    ),
  );

  const handlePendoTrackEnrollment = (
    type: 'create' | 'update' | 'delete',
    entityType: CourseEntityEnrollEnum,
    entityValue: Account | Branch | Group | User,
  ) => {
    if (course && account) {
      pendoTrackEnrollment({
        eventType:
          entityType === CourseEntityEnrollEnum.ACCOUNT
            ? pendoEvent.enrollAccount
            : entityType === CourseEntityEnrollEnum.BRANCHES
            ? pendoEvent.enrollBranch
            : entityType === CourseEntityEnrollEnum.GROUPS
            ? pendoEvent.enrollGroup
            : pendoEvent.enrollUser,
        type,
        courses: [course],
        accounts: [account],
        ...(entityType !== CourseEntityEnrollEnum.ACCOUNT
          ? { [entityType]: [entityValue] }
          : {}),
      });
    }
  };

  const deleteCourse = useDeleteCourse();
  const handleDeleteCourse = async (courseId: string) => {
    try {
      await deleteCourse.delete(courseId);
      toast?.success(
        t('toast.success'),
        isMaterial ? t('material.archived') : t('course.deleted'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
    navigate(materialByTypeListPath(course.type));
  };

  const menuItems = () => {
    const items = [];

    if (account?.isSystem) {
      items.push(
        ...[
          {
            label: t('generic.accounts.available'),
            icon: 'pi pi-check-circle',
            command: () => setShowMakeAvailableModal(true),
          },
          {
            label: t('generic.accounts.unavailable'),
            icon: 'pi pi-ban',
            command: () => setShowMakeUnavailableModal(true),
          },
        ],
      );

      if (!isPoster) {
        items.push({
          label: t('generic.unenroll'),
          icon: 'pi pi-times',
          command: () => {
            setCourseAccountsEnrollModal(true);
            setEnrollModalType(CourseEnrollmentTypeEnum.UNENROLL);
          },
        });
      }

      if (course?.id && onChangeCourseType && !isMaterial) {
        items.push({
          label: selfHosted
            ? t('courses.make.ccHosted')
            : t('courses.make.selfHosted'),
          icon: 'pi pi-question-circle',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.confirm'),
              message: t('dialog.change.course.host.type'),
              onAccept: () => onChangeCourseType(!selfHosted),
            }),
        });
      }

      if (
        account?.isSystem &&
        !retired &&
        can(Actions.UPDATE, Subjects.COURSES) &&
        !showDelete &&
        !isMaterial
      ) {
        items.push({
          label: t('generic.retire'),
          icon: 'pi pi-inbox',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.confirm'),
              message: t('dialog.change.course.retire'),
              onAccept: () => handleRetire(),
            }),
        });
      }
    } else if (!isPoster) {
      items.push(...enrollMenuItems(CourseEnrollmentTypeEnum.UNENROLL));
    }

    if (showDelete && (account?.isSystem || isCustomMaterial)) {
      items.push({
        label: isMaterial ? t('generic.archive') : t('generic.delete'),
        icon: 'pi pi-trash',
        command: async () => {
          setDialogData({
            type: 'confirmation',
            show: true,
            header: isMaterial
              ? t('dialog.archive.confirm')
              : t('dialog.delete.confirm'),
            message: isMaterial
              ? t('dialog.archive.material')
              : t('dialog.delete.course'),
            onAccept: async () => handleDeleteCourse(course.id),
          });
        },
      });
    }

    return items;
  };

  const handleSubmitAvailable = async (data: CourseAvailabilityFormValues) => {
    try {
      await saveAvailableAccounts.add({
        courseId: course?.id,
        accounts: data?.accounts?.map((account) => account.id),
      });
      toast?.success(t('toast.success'), t('courses.accounts.available.added'));
      setShowMakeAvailableModal(false);
      setShowDelete(false);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSubmitUnavailable = async (
    data: CourseAvailabilityFormValues,
  ) => {
    try {
      await saveUnavailableAccounts.remove({
        courseId: course?.id,
        accounts: data?.accounts?.map((account) => account.id),
      });
      toast?.success(
        t('toast.success'),
        t('courses.accounts.unavailable.added'),
      );
      setShowMakeUnavailableModal(false);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const updateCourse = useUpdateCourse();

  const handleRetire = async () => {
    try {
      await updateCourse.update({
        updates: { retired: true },
        courseId: course?.id,
      });

      toast?.success(
        t('toast.success'),
        isMaterial ? t('material.retire.success') : t('course.retire.success'),
      );

      navigate(materialByTypeListPath(course.type));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const enrollActionMenu = useRef<any>(null);
  const enrollMenuItems = (type: CourseEnrollmentTypeEnum) => {
    const isEnroll = type === CourseEnrollmentTypeEnum.ENROLL;

    return [
      ...(!isBranchAdmin
        ? [
            {
              label: isEnroll
                ? account?.name
                : `${t('generic.unenroll')} ${account?.name}`,
              icon: 'pi pi-building',
              command: () => {
                setEnrollModalEntityType(CourseEntityScheduleEnum.ACCOUNT);
                setEnrollModalType(type);
                isEnroll ? setEnrollModal(true) : setUnenrollModal(true);
              },
            },
          ]
        : []),
      {
        label: isEnroll
          ? t('branches')
          : `${t('generic.unenroll')} ${t('branches')}`,
        icon: 'pi pi-sitemap',
        command: () => {
          setEnrollModalEntityType(CourseEntityScheduleEnum.BRANCH);
          setEnrollModalType(type);
          isEnroll ? setEnrollModal(true) : setUnenrollModal(true);
        },
      },
      ...(can(Actions.UPDATE, Subjects.COURSE_GROUP_SCHEDULES) && !isBranchAdmin
        ? [
            {
              label: isEnroll
                ? t('groups')
                : `${t('generic.unenroll')} ${t('groups')}`,
              icon: 'pi pi-users',
              command: () => {
                setEnrollModalEntityType(CourseEntityScheduleEnum.GROUP);
                setEnrollModalType(type);
                isEnroll ? setEnrollModal(true) : setUnenrollModal(true);
              },
            },
          ]
        : []),
      {
        label: isEnroll ? t('users') : `${t('generic.unenroll')} ${t('users')}`,
        icon: 'pi pi-user',
        command: () => {
          setEnrollModalEntityType(CourseEntityScheduleEnum.USER);
          setEnrollModalType(type);
          isEnroll ? setEnrollModal(true) : setUnenrollModal(true);
        },
      },
    ];
  };

  const systemEnrollActionMenu = useRef<any>(null);

  const systemEnrollMenuItems = () => {
    return [
      {
        label: t('accounts'),
        icon: 'pi pi-building',
        command: () => {
          setCourseAccountsEnrollModal(true);
          setEnrollModalType(CourseEnrollmentTypeEnum.ENROLL);
        },
      },
    ];
  };

  const handleCourseEnrollment = (
    event?: SyntheticEvent<HTMLButtonElement>,
  ) => {
    enrollActionMenu.current.toggle(event);
  };

  const handleSystemCourseEnrollment = (
    event?: SyntheticEvent<HTMLButtonElement>,
  ) => {
    systemEnrollActionMenu.current.toggle(event);
  };

  const checkAndTrackHubspotEvent = () => {
    if (user?.account?.freeTrialEndsAt && !isMaterial) {
      hubspotTrack({
        [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
      });
    }
  };

  const accountsSchedules = useAccountsSchedules();
  const handleSubmitEnrollAccounts = async (data: AccountsEnrollFormValues) => {
    try {
      await accountsSchedules.add({
        accounts: data?.accounts?.map((account) => account.id),
        courses: [course?.id],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollAccount,
          type: 'create',
          courses: [course],
          accounts: data?.accounts,
          date: data.date,
          autoEnroll: data.autoEnroll,
        });
      }

      if (!account?.isSystem) {
        toast?.success(
          t('toast.success'),
          t('courses.accountName.enroll.success', {
            accountName: account?.name,
          }),
        );
        setEnrollModal(false);
      } else {
        toast?.success(
          t('toast.success'),
          t('courses.accounts.enroll.success'),
        );
        setCourseAccountsEnrollModal(false);
      }

      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const unenrollEntity = useDeleteEnrollment();
  const handleSubmitUnenroll = async ({
    courseId,
    entity,
    value,
  }: DeleteEnrollmentFormValues) => {
    try {
      await unenrollEntity.post({
        courseId,
        request: { [entity]: value.map((x) => x.id) },
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isResource) {
        value.forEach((entityData: Account | Branch | Group | User) => {
          track('Delete course enrollment', {
            Entity: mixpanelEntity[entity],
            'Course name': course.name,
            'Course id': course.id,
            'Enrolling to': entityData.name,
            'Account name': account?.name,
            'Current date (UTC)': moment.utc().format('MMMM Do YYYY'),
          });

          handlePendoTrackEnrollment('delete', entity, entityData);
        });
      }

      toast?.success(
        t('toast.success'),
        t(`courses.${entity}.unenroll.success`),
      );
      setUnenrollModal(false);
      setCourseAccountsEnrollModal(false);

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const branchesSchedules = useBranchesSchedules();
  const handleSubmitEnrollBranches = async (data: EnrollFormValues) => {
    try {
      await branchesSchedules.add({
        courses: [course?.id],
        branches: data?.branches?.map((branch) => branch.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollBranch,
          type: 'create',
          courses: [course],
          accounts: [account as Account],
          branches: data?.branches,
          date: data.date,
          autoEnroll: data.autoEnroll,
        });
      }

      toast?.success(t('toast.success'), t('courses.branches.enroll.success'));
      setEnrollModal(false);

      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const groupsSchedule = useGroupsSchedules();
  const handleSubmitEnrollGroups = async (data: EnrollFormValues) => {
    try {
      await groupsSchedule.add({
        courses: [course?.id],
        groups: data?.groups?.map((group) => group.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: data.type,
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollGroup,
          type: 'create',
          courses: [course],
          accounts: [account as Account],
          groups: data?.groups,
          date: data.date,
          autoEnroll: data.autoEnroll,
        });
      }

      toast?.success(t('toast.success'), t('courses.group.enroll.success'));
      setEnrollModal(false);

      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const usersSchedules = useUsersSchedules();
  const handleSubmitEnrollUsers = async (data: EnrollFormValues) => {
    try {
      await usersSchedules.add({
        courses: [course?.id],
        users: data?.users?.map((user) => user.id) ?? [],
        date: data.date,
        type: data.type,
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        pendoTrackEnrollment({
          eventType: pendoEvent.enrollUser,
          type: 'create',
          courses: [course],
          accounts: [account as Account],
          users: data?.users,
          date: data.date,
        });
      }

      toast?.success(t('toast.success'), t('courses.users.enroll.success'));
      setEnrollModal(false);

      checkAndTrackHubspotEvent();

      if (onTriggerRefetch) {
        onTriggerRefetch();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleEnrollModalSubmit = async (
    data: EnrollFormValues,
    type?: CourseEnrollmentTypeEnum,
  ) => {
    if (!type) return;
    const isEnroll = type === CourseEnrollmentTypeEnum.ENROLL;

    switch (enrollModalEntityType) {
      case CourseEntityScheduleEnum.ACCOUNT: {
        isEnroll
          ? await handleSubmitEnrollAccounts(data as AccountsEnrollFormValues)
          : await handleSubmitUnenroll({
              courseId: course.id,
              entity: CourseEntityEnrollEnum.ACCOUNT,
              value: data?.accounts as Account[],
            });
        break;
      }
      case CourseEntityScheduleEnum.BRANCH: {
        isEnroll
          ? await handleSubmitEnrollBranches(data)
          : await handleSubmitUnenroll({
              courseId: course.id,
              entity: CourseEntityEnrollEnum.BRANCHES,
              value: data?.branches as Branch[],
            });
        break;
      }
      case CourseEntityScheduleEnum.GROUP: {
        isEnroll
          ? await handleSubmitEnrollGroups(data)
          : await handleSubmitUnenroll({
              courseId: course.id,
              entity: CourseEntityEnrollEnum.GROUPS,
              value: data?.groups as Group[],
            });
        break;
      }
      case CourseEntityScheduleEnum.USER: {
        isEnroll
          ? await handleSubmitEnrollUsers(data)
          : await handleSubmitUnenroll({
              courseId: course.id,
              entity: CourseEntityEnrollEnum.USERS,
              value: data?.users as User[],
            });
        break;
      }
      default:
        break;
    }
  };

  const isEnrollButtonVisible = () => {
    const routes = [
      RedirectPaths[RedirectPathsEnum.COURSES_USERS](course?.id),
      RedirectPaths[RedirectPathsEnum.CUSTOM_MATERIALS_USERS](course?.id),
      RedirectPaths[RedirectPathsEnum.RESOURCES_USERS](course?.id),
    ];

    return (
      !canUseFeature(Subjects.TRAINING_PLANNER) ||
      (canUseFeature(Subjects.TRAINING_PLANNER) &&
        routes.includes(location.pathname))
    );
  };

  return (
    <>
      <AppBreadCrumb
        model={getBreadcrumbs(
          account as Account,
          course,
          language as LanguagesEnum,
          location,
          t,
        )}
      />

      <FlexContainer justify="space-between" align="flex-start" gap={16}>
        <FlexContainer
          gap={12}
          justify="flex-start"
          align="flex-start"
          flex="1"
        >
          <h2 className="m-0">
            {
              getTranslationByLanguage(
                course?.courseTranslations,
                language as LanguagesEnum,
              )?.name
            }
          </h2>
          {!retired ? (
            <AppChip label={t('generic.active')} type="primary" />
          ) : (
            <AppChip label={t('generic.retired')} type="error" />
          )}
          {course?.account && account?.isSystem && (
            <AppChip
              label={
                isMaterial
                  ? t('generic.custom.content')
                  : t('courses.custom.content')
              }
              type="primary"
            />
          )}
        </FlexContainer>
        {!retired && (
          <FlexContainer gap={12} justify="flex-end" flex="0">
            {account?.isSystem && !isPoster ? (
              <>
                <Menu
                  id="overlay_menu_system_enroll"
                  model={systemEnrollMenuItems()}
                  popup
                  ref={systemEnrollActionMenu}
                />
                <AppButton
                  label={t('generic.enroll')}
                  aria-controls="overlay_menu_system_enroll"
                  onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                    handleSystemCourseEnrollment(e);
                  }}
                />
              </>
            ) : (
              <>
                {isEnrollButtonVisible() && (
                  <>
                    <Menu
                      id="overlay_menu_enroll"
                      model={enrollMenuItems(CourseEnrollmentTypeEnum.ENROLL)}
                      popup
                      ref={enrollActionMenu}
                    />
                    <AppButton
                      label={t('generic.enroll')}
                      aria-controls="overlay_menu_enroll"
                      onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                        if (canUseFeature(Subjects.TRAINING_PLANNER)) {
                          setEnrollModalEntityType(
                            CourseEntityScheduleEnum.USER,
                          );
                          setEnrollModalType(CourseEnrollmentTypeEnum.ENROLL);
                          setEnrollModal(true);
                        } else {
                          handleCourseEnrollment(e);
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
            {!!menuItems()?.length && (
              <DataTableActions menuItems={menuItems()} />
            )}
          </FlexContainer>
        )}

        <CourseAvailabilityModal
          type={CourseAvailabilityTypeEnum.MAKE_AVAILABLE}
          visible={showMakeAvailableModal}
          onSubmit={handleSubmitAvailable}
          onHide={() => setShowMakeAvailableModal(false)}
          course={course}
        />

        <CourseAvailabilityModal
          type={CourseAvailabilityTypeEnum.MAKE_UNAVAILABLE}
          visible={showMakeUnavailableModal}
          onSubmit={handleSubmitUnavailable}
          onHide={() => setShowMakeUnavailableModal(false)}
          course={course}
        />

        <CourseAccountsEnrollModal
          type={enrollModalType}
          course={course}
          visible={showCourseAccountsEnrollModal}
          onSubmit={(data: AccountsEnrollFormValues) =>
            enrollModalType === CourseEnrollmentTypeEnum.ENROLL
              ? handleSubmitEnrollAccounts(data)
              : handleSubmitUnenroll({
                  courseId: course.id,
                  entity: CourseEntityEnrollEnum.ACCOUNT,
                  value: data.accounts,
                })
          }
          onHide={() => setCourseAccountsEnrollModal(false)}
        />

        <EnrollModal
          courseId={
            enrollModalType === CourseEnrollmentTypeEnum.UNENROLL
              ? course.id
              : undefined
          }
          type={enrollModalType}
          account={account as Account}
          enrollType={enrollModalEntityType}
          visible={
            enrollModalType === CourseEnrollmentTypeEnum.ENROLL
              ? showEnrollModal
              : showUnenrollModal
          }
          onSubmit={(data: EnrollFormValues) => {
            if (showEnrollModal) {
              handleEnrollModalSubmit(data, enrollModalType);
            } else if (showUnenrollModal) {
              handleEnrollModalSubmit(data, enrollModalType);
            }
          }}
          onHide={() => {
            if (showEnrollModal) {
              setEnrollModal(false);
            } else if (showUnenrollModal) {
              setUnenrollModal(false);
            }
          }}
          course={course}
        />
      </FlexContainer>
    </>
  );
};
