import { Campaign, CampaignSummary } from '@/client/campaigns';
import { Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import {
  CampaignClickedIconPath,
  CampaignReportedIconPath,
  CampaignsReportsIconPath,
  CampaignSubmittedIconPath,
  EmailOpenedIconPath,
  EmailSentIconPath,
} from '@/utils/helpers';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardCampaignsReport = styled.div`
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--default-padding);
    align-items: center;
    gap: var(--small-padding);

    .reports-card-title {
      font-weight: 500;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      text-transform: uppercase;
      margin: 0;
    }
    .icon-wrapper {
      height: 32px;
      width: 32px;
      background-color: var(--white-main);
      display: flex;
      justify-content: center;
      border-radius: var(--xxsmall-border-radius);
      .custom-icon {
        align-self: center;
        fill: var(--purple-dark);
        cursor: pointer;
      }
    }
  }

  .phishing-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--small-padding);
  }
`;

type CampaignsReportsPropsType = {
  t: TranslationFunctionType;
  campaign?: Campaign;
  campaignsSummary?: CampaignSummary;
  skipADConfigFetch?: boolean;
  isLoading?: boolean;
  isDashboard?: boolean;
  isBenchmark?: boolean;
} & React.HTMLAttributes<any>;

export const CampaignsReports = ({
  t,
  campaign,
  campaignsSummary,
  isLoading,
  isDashboard,
  isBenchmark,
  ...rest
}: CampaignsReportsPropsType) => {
  const navigate = useNavigate();
  const { canUseFeature } = useFeatureFlag();

  return (
    <StyledCardCampaignsReport {...rest}>
      {isDashboard && (
        <div className="header">
          <h3 className="reports-card-title">
            {t('reports.phishing.overview')}
          </h3>
          <span
            className="icon-wrapper"
            onClick={() =>
              navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]())
            }
          >
            <svg
              className="custom-icon"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <CampaignsReportsIconPath />
            </svg>
          </span>
        </div>
      )}
      <div className="phishing-overview">
        <DoughnutChart
          allCount={campaignsSummary ? Number(campaignsSummary?.total) : 0}
          singleCount={
            campaignsSummary ? Number(campaignsSummary?.emailsSent) : 0
          }
          id="3"
          label={t('campaign.emailSent')}
          cutout="70%"
          width="160px"
          isDashboard={isDashboard}
          isBenchmark={isBenchmark}
          labelIcon={<EmailSentIconPath />}
          isLoading={isLoading}
        />
        {canUseFeature(Subjects.EMAIL_OPEN_EVENTS) && (
          <DoughnutChart
            allCount={campaignsSummary ? Number(campaignsSummary?.total) : 0}
            singleCount={
              campaignsSummary ? Number(campaignsSummary?.emailsOpen) : 0
            }
            id="4"
            label={t('campaign.emailOpened')}
            cutout="70%"
            width="160px"
            isDashboard={isDashboard}
            isBenchmark={isBenchmark}
            labelIcon={<EmailOpenedIconPath />}
            isLoading={isLoading}
          />
        )}
        <DoughnutChart
          allCount={campaignsSummary ? Number(campaignsSummary?.total) : 0}
          singleCount={
            campaignsSummary ? Number(campaignsSummary?.clickedLinks) : 0
          }
          benchmark={{
            allCount: 100,
            singleCount:
              campaign?.benchmarkSummary?.clickedLinksPercentage || 0,
          }}
          id="5"
          label={t('campaign.clicked')}
          cutout="70%"
          width="160px"
          isDashboard={isDashboard}
          isBenchmark={isBenchmark}
          labelIcon={<CampaignClickedIconPath />}
          isLoading={isLoading}
        />
        <DoughnutChart
          allCount={campaignsSummary ? Number(campaignsSummary?.total) : 0}
          singleCount={
            campaignsSummary ? Number(campaignsSummary?.submittedData) : 0
          }
          benchmark={{
            allCount: 100,
            singleCount:
              campaign?.benchmarkSummary?.submittedDataPercentage || 0,
          }}
          id="6"
          label={t('campaign.submitted')}
          cutout="70%"
          width="160px"
          isDashboard={isDashboard}
          isBenchmark={isBenchmark}
          labelIcon={<CampaignSubmittedIconPath />}
          isLoading={isLoading}
        />
        {canUseFeature(Subjects.OUTLOOK_PHISHING_EMAIL_REPORTING) && (
          <DoughnutChart
            allCount={campaignsSummary ? Number(campaignsSummary?.total) : 0}
            singleCount={
              campaignsSummary ? Number(campaignsSummary?.emailsReported) : 0
            }
            benchmark={{
              allCount: 100,
              singleCount:
                campaign?.benchmarkSummary?.emailsReportedPercentage || 0,
            }}
            id="6"
            label={t('campaign.email.reported')}
            cutout="70%"
            width="160px"
            isDashboard={isDashboard}
            isBenchmark={isBenchmark}
            labelIcon={<CampaignReportedIconPath />}
            isLoading={isLoading}
          />
        )}
      </div>
    </StyledCardCampaignsReport>
  );
};
