import { extractSubdomain, isKeycloakEnabled } from '@/auth';
import { Subjects, SystemRoles } from '@/client/users';
import { LAST_SELECTED_LANGUAGE } from '@/common/constants';
import { TOKEN_EXPIRES_KEY } from '@/common/constants/user';
import { LanguageContext } from '@/common/context/LanguageContext';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { usePendo } from '@/hooks/usePendo';
import { LoadingPage } from '@/pages';
import {
  getAccountBySubdomainAction,
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import {
  loadCurrentUserAction,
  selectCurrentUserState,
} from '@/store/features/users';
import { isOutsideOfEfront } from '@/utils/helpers';
import { identifyHubspotContact } from '@/utils/hubspot';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import moment from 'moment/moment';
import { Message } from 'primereact/message';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import { Md5 } from 'ts-md5';

export const AuthLoadUser: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tokenExpiresOn = sessionStorage.getItem(TOKEN_EXPIRES_KEY);
  const account = useAppSelector(selectCurrentAccount);
  const { changeLanguage } = useContext(LanguageContext);
  const { signoutSilent } = useAuth();
  const { pendoIdentify } = usePendo();
  const { canUseFeature } = useFeatureFlag();

  const { identify, people, reset: resetMixpanel } = useMixpanel();

  const { loading, loaded, user, error } = useAppSelector(
    selectCurrentUserState,
  );

  useEffect(() => {
    if (
      !isKeycloakEnabled() &&
      (!tokenExpiresOn || moment(tokenExpiresOn).utc().isBefore(moment().utc()))
    ) {
      return;
    }

    loadCurrentUserAction(dispatch);
  }, [tokenExpiresOn]);

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }

    // Init trackings
    if (canUseFeature(Subjects.EVENT_TRACKING)) {
      if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
        // Initialize Mixpanel
        mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
          // debug: true,
          ignore_dnt: true,
          ip: false,
          persistence: 'localStorage',
          property_blacklist: [
            '$city',
            '$region',
            '$country_code',
            '$geo_source',
            '$timezone',
          ],
        });
        // Identify and store user profile with Mixpanel
        identify(Md5.hashStr(user.id));
        people.set({
          $first_name: Md5.hashStr(user.id),
          $created: user.created,
          'User role': user.role.name,
          'Account name': user.account.name,
        });
      }

      // Initialize Pendo
      const existingPendoScript = document.getElementById(
        'pendo-script-loader',
      );
      if (!existingPendoScript && process.env.REACT_APP_PENDO_KEY) {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.id = 'pendo-script-loader';
        script.async = true;
        script.defer = true;
        script.src = `https://cdn.eu.pendo.io/agent/static/${process.env.REACT_APP_PENDO_KEY}/pendo.js`;
        script.onload = () => {
          setTimeout(() => {
            if (pendo) {
              pendo.initialize();
              pendoIdentify(user);
            }
          }, 1000);
        };

        document.body.appendChild(script);
      }
    }
    changeLanguage(user.language);
    Sentry.setUser({ id: Md5.hashStr(user.id) });

    if (
      user?.role?.code !== SystemRoles.LEARNER ||
      user?.account?.freeTrialEndsAt
    ) {
      identifyHubspotContact(user);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!account) {
      const subdomain = extractSubdomain(window.location);

      if (
        isOutsideOfEfront() &&
        !!subdomain &&
        subdomain !== 'app' &&
        subdomain !== 'awareness'
      ) {
        dispatch(getAccountBySubdomainAction(window.location));
        return;
      }

      dispatch(setCurrentAccount(user.account));
    }
  }, [user, account]);

  useEffect(() => {
    if (!error) {
      return;
    }

    signoutSilent();
    sessionStorage.clear();
    // Reset distinc ID in Mixpanel
    resetMixpanel();
  }, [error]);

  if (error) {
    return <Message text={t('user.loadingError')} severity="error" />;
  }

  if (loading || !loaded || !account) {
    return <LoadingPage message={t('user.loading')} />;
  }

  if (loaded && user?.language) {
    localStorage.setItem(LAST_SELECTED_LANGUAGE, user.language);
  }

  return <Outlet />;
};
