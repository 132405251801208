import { Actions, Subjects } from '@/client/users';
import { DashboardType } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import {
  selectCurrentAccount,
  selectCurrentAccountFeatureAbility,
} from '@/store/features/account/current-account.slice';
import { selectCurrentDashboard } from '@/store/features/dashboard';
import {
  selectCurrentUser,
  selectCurrentUserFeatureAbility,
} from '@/store/features/users/current-user.slice';

// Custom hook to determine feature permissions
export const useFeatureFlag = () => {
  const user = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const userAbility = useAppSelector(selectCurrentUserFeatureAbility);
  const accountAbility = useAppSelector(selectCurrentAccountFeatureAbility);
  const selectedDashboard = useAppSelector(selectCurrentDashboard);

  const canUseFeature = (feature: Subjects) => {
    if (
      user?.account?.id === currentAccount?.id ||
      selectedDashboard === DashboardType.LEARNER
    ) {
      return userAbility ? userAbility.can(Actions.READ, feature) : false;
    } else {
      return accountAbility ? accountAbility.can(Actions.READ, feature) : false;
    }
  };

  return { canUseFeature };
};
