import NotFoundImage from '@/images/placeholders/not-found.svg';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledImg = styled.img`
  height: auto;
  width: 100%;
`;

const StyledFlexContainer = styled(FlexContainer)`
  border-radius: var(--medium-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
  overflow-x: hidden;

  h1 {
    font-size: var(--heading-large-font-size);
    line-height: var(--heading-large-line-height);
    text-align: center;
    margin-top: var(--default-padding);
  }

  p {
    text-align: center;
    line-height: var(--medium-line-height);
    font-weight: 500;
    text-align: center;
    margin: 0;
  }
`;

export const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <StyledFlexContainer height="100%" direction="column">
      <StyledImg
        style={{ maxHeight: '316px' }}
        src={NotFoundImage}
        alt={t('courses.noCourses.heading')}
      />
      <h1>{t('error.page.404')}</h1>
      <p>{t('error.page.notFound')}</p>
    </StyledFlexContainer>
  );
};
