export const CURRENT_ACCOUNT_KEY = 'current-account';
export const LAST_SELECTED_LANGUAGE = 'lastSelectedLanguage';
export const LAST_SELECTED_DASHBOARD = 'lastSelectedDashboard';
export const LAST_SELECTED_SC_SURVEY = 'lastSelectedSecurityCultureSurvey';
export const LAST_SELECTED_ACCOUNT_SETTINGS = 'lastSelectedAccountSettings';

export const DashboardType = {
  ADMIN: 'admin',
  LEARNER: 'learner',
};
