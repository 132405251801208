import {
  CampaignHistoryEvent,
  CampaignUserResult,
  CampaignUserResultMessages,
  CampaignUserResultMessagesType,
  CampaignUserResultStatusesEnum,
} from '@/client/campaigns';
import { Subjects } from '@/client/users';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { DateFormats } from '@/system-settings/enums';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { Card } from 'primereact/card';
import { Timeline, TimelineProps } from 'primereact/timeline';
import { Tree } from 'primereact/tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUA } from 'use-ua-parser-js';

const StyledTimeline = styled(Timeline)`
  &.p-timeline.p-timeline.p-timeline-vertical {
    padding: var(--default-padding);
    .p-timeline-event .p-timeline-event-opposite {
      display: none;
    }
    .p-timeline-event-content {
      padding-bottom: var(--default-padding);
    }
    .pi {
      font-size: var(--heading-small-font-size);
      line-height: var(--heading-small-line-height);
      &.pi-fw {
        font-size: var(--small-font-size);
        line-height: var(--small-line-height);
      }
    }
    .pi-envelope-open:before {
      font-family: var(--icon-font-family);
      display: inline-block;
      color: var(--white-main);
      margin-bottom: 4px;
      font-weight: 500;
      content: '\f2b6';
    }
  }
`;
const StyledContainer = styled.div`
  padding: 0 var(--small-padding);
  text-align: left;
`;
const StyledCard = styled(Card)`
  &.p-card {
    background: transparent;
    border: none;
    box-shadow: none;
    .p-card-body {
      padding-top: 4px;
      padding-bottom: 4px;
      color: var(--black-main);
    }
    .p-card-title {
      font-size: var(--medium-font-size);
      line-height: var(--medium-line-height);
      margin: 0;
    }
    .p-card-subtitle {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      margin: 0;
      margin-top: 4px;
    }
  }
`;
const StyledTree = styled(Tree)`
  &.p-tree {
    padding: 0;
    border: none;
    background: transparent;
    .p-treenode {
      padding: 0;
      .p-treenode-content {
        padding: 4px;
      }
    }
    .p-treenode-label {
      font-weight: 500;
    }
    .p-treenode-children {
      padding: 0;
      padding-bottom: var(--xsmall-padding);
      .p-treenode-content {
        padding: 0;
        pointer-events: none;
      }
    }
  }
`;

const StyledSpan = styled.span`
  width: 36px;
  height: 36px;
`;

type CampaingUserEventsTimelineProps = {
  userResults?: CampaignUserResult;
  campaignCreationDate: Date;
  status: CampaignUserResultStatusesEnum;
} & TimelineProps;

const icons: Partial<Record<CampaignUserResultStatusesEnum, string>> = {
  [CampaignUserResultStatusesEnum.CAMPAIGN_CREATED]: 'pi pi-cog',
  [CampaignUserResultStatusesEnum.EMAIL_SENT]: 'pi pi-envelope',
  [CampaignUserResultStatusesEnum.EMAIL_OPENED]: 'pi pi-envelope-open',
  [CampaignUserResultStatusesEnum.CLICKED_LINK]: 'pi pi-link',
  [CampaignUserResultStatusesEnum.SUBMITTED_DATA]: 'pi pi-exclamation-circle',
  [CampaignUserResultStatusesEnum.EMAIL_REPORTED]: 'pi pi-times-circle',
  [CampaignUserResultStatusesEnum.ERROR_SENDING_EMAIL]:
    'pi pi-exclamation-circle',
  [CampaignUserResultStatusesEnum.SCHEDULED]: 'pi pi-clock',
};

const colors: Partial<Record<CampaignUserResultStatusesEnum, string>> = {
  [CampaignUserResultStatusesEnum.CAMPAIGN_CREATED]: '#22C55E',
  [CampaignUserResultStatusesEnum.EMAIL_SENT]: '#22C55E',
  [CampaignUserResultStatusesEnum.EMAIL_OPENED]: '#F59E0B',
  [CampaignUserResultStatusesEnum.CLICKED_LINK]: '#ff6c00',
  [CampaignUserResultStatusesEnum.SUBMITTED_DATA]: '#EF4444',
  [CampaignUserResultStatusesEnum.EMAIL_REPORTED]: '#22C55E',
  [CampaignUserResultStatusesEnum.ERROR_SENDING_EMAIL]: '#8e8e8e',
  [CampaignUserResultStatusesEnum.SCHEDULED]: '#3B82F6',
};

const getStatusSeverity = (
  status: CampaignUserResultMessagesType,
  t: (key: string) => string,
) => {
  if (CampaignUserResultMessages[status] ?? null) {
    return t(CampaignUserResultMessages[status]);
  }

  return null;
};

export const CampaignUserEventsTimeline: React.FC<
  CampaingUserEventsTimelineProps
> = ({ userResults, campaignCreationDate, status }) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();

  const events: any[] = [
    {
      status: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.CAMPAIGN_CREATED
        ],
      ),
      date: campaignCreationDate,
      userAgent: null,
      icon: icons[CampaignUserResultStatusesEnum.CAMPAIGN_CREATED],
      color: colors[CampaignUserResultStatusesEnum.CAMPAIGN_CREATED],
    },
  ];
  if (
    !userResults?.events.length &&
    status !== CampaignUserResultStatusesEnum.SENDING
  ) {
    events.push({
      status: (
        <>
          {t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.SCHEDULED
            ],
          )}{' '}
          {t('camapign.toSendOn')}{' '}
          <FormatDate
            format={DateFormats.TIMEDATE}
            date={userResults?.meta?.sendDate}
          />
        </>
      ),
      date: null,
      userAgent: null,
      icon: icons[CampaignUserResultStatusesEnum.SCHEDULED],
      color: colors[CampaignUserResultStatusesEnum.SCHEDULED],
    });
  } else if (userResults?.events.length) {
    userResults?.events.forEach((event: CampaignHistoryEvent) => {
      if (
        (canUseFeature(Subjects.EMAIL_OPEN_EVENTS) &&
          CampaignUserResultStatusesEnum.EMAIL_OPENED === event.status) ||
        CampaignUserResultStatusesEnum.EMAIL_OPENED !== event.status
      ) {
        events.push({
          status: getStatusSeverity(event.status, t),
          date: event.time,
          userAgent:
            event?.meta?.browser && event?.meta?.browser['user-agent']
              ? event.meta.browser['user-agent']
              : null,
          icon: icons[event.status],
          color: colors[event.status],
        });
      }
    });
  }

  const customizedMarker = (item: any) => {
    return (
      <StyledSpan
        className="flex align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: item.color }}
      >
        <i className={item.icon}></i>
      </StyledSpan>
    );
  };

  const customizedContent = (item: any) => {
    let details = undefined;

    if (item.userAgent) {
      const UADetails = useUA(item.userAgent);

      details = [
        {
          key: 'details',
          label: t('campaign.details'),
          data: 'Details',
          children: [
            {
              key: 'os',
              label: `${UADetails?.os.name} (${UADetails?.os.version})`,
              data: `${UADetails?.os.name} (${UADetails?.os.version})`,
              icon: 'pi pi-desktop',
            },
            {
              key: 'browser',
              label: `${UADetails?.browser.name} (${UADetails?.browser.version})`,
              data: `${UADetails?.browser.name} (${UADetails?.browser.version})`,
              icon: 'pi pi-globe',
            },
          ],
        },
      ];
    }

    return (
      <>
        <StyledCard
          title={item.status}
          subTitle={
            item.date && (
              <FormatDate format={DateFormats.TIMEDATE} date={item.date} />
            )
          }
        />
        {item.userAgent && details && <StyledTree value={details} />}
      </>
    );
  };

  if (!userResults)
    <FlexContainer direction="column">
      <h3>{t('campaign.noTimeline')}</h3>
    </FlexContainer>;

  return (
    <StyledContainer>
      <h2>
        {t('campaign.timeline', {
          name: userResults?.firstName + ' ' + userResults?.lastName,
        })}
      </h2>
      <div className="pl-4">
        <p>
          <b>{t('generic.email')}:</b> {userResults?.email}
        </p>
        <p>
          <b>{t('campaign.timeline.resultId')}:</b> {userResults?.meta?.id}
        </p>
      </div>

      <div>
        <StyledTimeline
          className="w-full"
          value={events}
          align="left"
          marker={customizedMarker}
          content={customizedContent}
        />
      </div>
    </StyledContainer>
  );
};
