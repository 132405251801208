import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account, DuplicatedUsersRow } from '@/client/accounts';
import { getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, SystemRoles, User } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { AccountCard } from '@/components/accounts/AccountCard';
import {
  DataTable,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccount,
  useAccountUsers,
  useDuplicatedUsers,
  useMigrateDuplicatedUsers,
  useSaveADConfig,
  useUpdateAccounts,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { AccountHeader } from './AccountHeader';

const StyledOverlayPanel = styled(OverlayPanel)`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
`;

const StyledSpan = styled('span')`
  display: inline-flex;
  width: 100%;
`;

const StyledLabel = styled.label`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
  font-weight: 500;
  color: var(--purple-dark);
`;

const StyledHoverText = styled.div`
  color: var(--red-main);
  cursor: pointer;
  margin: 0;

  &:hover {
    color: var(--red-dark);
  }
`;

const StyledCardsWrapper = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--default-padding);
  padding-bottom: var(--default-padding);
  flex-wrap: wrap;
  overflow: hidden;
`;

const StyledTitle = styled.h3`
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--black-main);
`;

const pathItems = (
  account: Account,
  currentAccount: Account,
  t: (key: string, placeholder?: { name: string }) => string,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }
  items.push({
    label: t('migration'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_MIGRATION](account?.id),
    template: AppBreadCrumbTemplate,
  });

  return items;
};

export const AccountMigrationPage = () => {
  const { t } = useTranslation();
  const { id: accountId } = useParams();
  const { can } = usePermission();
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const op = useRef<OverlayPanel>(null);
  const [deactivationLoading, setDeactivationLoading] = useState(
    LoadingStatuses.IDLE,
  );
  const [efrontSyncLoading, setEfrontSyncLoading] = useState(
    LoadingStatuses.IDLE,
  );
  const [notSyncCoursesMessage, setNotSyncCoursesMessage] = useState<string[]>(
    [],
  );

  const {
    account,
    isLoading: accountIsLoading,
    refetch: accountRefetch,
  } = useAccount({ accountId });

  const hasAdConfig = !!account?.activeDirectoryIntegration;

  const { skip, take, sort, apiFilters, onSort, onPage, onFilter } = useTable();

  const {
    users: activePhishingOnlyUsers,
    refetch: refetchActivePhishingOnlyUsers,
    isLoading: isPhishingOnlyUsersLoading,
  } = useAccountUsers({
    accountId: accountId,
    filters: [
      equal('email', 'phishing_only@cyberpilot.io'),
      equal('active', true),
    ],
  });

  const {
    duplicatedUsers,
    isLoading: duplicateUsersAreLoading,
    refetch: refetchDuplicateUsers,
  } = useDuplicatedUsers(
    {
      take,
      skip,
      filters: apiFilters,
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    },
    accountId,
  );

  type DuplicatedUsersSelectType = {
    [key: string]: boolean;
  };

  const [initialValues, setInitialValues] = useState<DuplicatedUsersSelectType>(
    {},
  );
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOneSelected, setIsOneSelected] = useState(false);

  const updateAccount = useUpdateAccounts();
  const saveADConfig = useSaveADConfig();
  const migrateUsers = useMigrateDuplicatedUsers();

  const {
    lastMessage: accountLastMessage,
    setNotificationParam: setNotificationAccountId,
    notificationParam: notificationAccountId,
    isConnected: accountNotificationIsConnected,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  useEffect(() => {
    if (
      !duplicateUsersAreLoading &&
      duplicatedUsers &&
      duplicatedUsers.result.length
    ) {
      duplicatedUsers.result.map((x) =>
        setInitialValues((state) => ({ ...state, [x.email]: false })),
      );
    }
  }, [duplicatedUsers]);

  useEffect(() => {
    setIsOneSelected(Object.values(initialValues).includes(true));
    setIsAllSelected(!Object.values(initialValues).includes(false));
  }, [initialValues]);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleAccountNotification(accountLastMessage);
  }, [JSON.stringify(accountLastMessage)]);

  const sendHistoricalData = async () => {
    if (!accountId) return;
    const key = v4();

    try {
      await client.tracking.sendHistoricalData({
        key,
        accounts: [accountId],
        endDate: moment().utc(true).toDate(),
      });
      toast?.success(
        t('toast.success'),
        t('account.sending.historicalData.queued'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleAccountMigration = async (e: CheckboxChangeEvent) => {
    if (!accountId) return;
    try {
      await updateAccount.update({
        updates: {
          eFrontSync: !e.checked,
          isMigratedOutsideOfEFront: e.checked,
        },
        accountId,
      });

      if (hasAdConfig) {
        await saveADConfig.create({
          updates: {
            active: !!e.checked,
          },
          accountId,
        });
      }

      await accountRefetch();

      toast?.success(t('toast.success'), t('account.updated'));

      if (e.checked) {
        sendHistoricalData();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSendEmails = async () => {
    try {
      if (!account) return;
      await client.accountMigrationService.sendEmailsToMigratedUsers(
        account.id,
      );
      await accountRefetch();
      toast?.success(
        t('toast.success'),
        t('system.email.migrated.users.send.success'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleAccountNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      ![
        'account.migration.eFront-users',
        'account.eFront.mapped-courses.synced',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages.data.event === 'account.migration.eFront-users') {
      toast?.success(
        t('toast.success'),
        t('account.migration.deactivateUsers.success'),
      );
      setDeactivationLoading(LoadingStatuses.IDLE);
    }

    if (messages.data.event === 'account.eFront.mapped-courses.synced') {
      toast?.success(
        t('toast.success'),
        t('account.migration.syncEfrontCourses.success'),
      );
      setEfrontSyncLoading(LoadingStatuses.IDLE);
      setNotSyncCoursesMessage(
        messages.data?.payload?.notAvailableEFrontCourses ?? [],
      );
    }

    setNotificationAccountId(undefined);
  };

  const handleDeactivateEfrontUsers = async () => {
    if (!account) return;
    setNotificationAccountId(account.id);
    setDeactivationLoading(LoadingStatuses.LOADING);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (accountNotificationIsConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (accountNotificationIsConnected.current) {
        await client.accountMigrationService.deactivateEfrontUsers(account.id);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeactivationLoading(LoadingStatuses.IDLE);
      setNotificationAccountId(undefined);
    }
  };

  const handleUsersMigration = async (data: DuplicatedUsersSelectType) => {
    const emails = Object.keys(data).filter((x) => data[x] === true);
    if (!accountId) return;
    try {
      await migrateUsers.migrate({ accountId, payload: { emails } });
      await refetchDuplicateUsers();
      await refetchActivePhishingOnlyUsers();
      setIsOneSelected(false);
      toast?.success(
        t('toast.success'),
        isOneSelected && duplicatedUsers?.count !== 0
          ? t('users.migrated.success')
          : t('users.deactivate.phishingOnly.success'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleEfrontCoursesReSync = async () => {
    if (!account) return;
    setNotificationAccountId(account.id);
    setEfrontSyncLoading(LoadingStatuses.LOADING);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (accountNotificationIsConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (accountNotificationIsConnected.current) {
        await client.accountMigrationService.reSyncEfrontCourses(account.id);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setEfrontSyncLoading(LoadingStatuses.IDLE);
      setNotificationAccountId(undefined);
    }
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'select',
      style: { width: '54px' },
      className: 'vertical-top',
      header: (
        <Checkbox
          name="selectAll"
          onChange={(e) => {
            if (duplicatedUsers?.count != 0) {
              Object.keys(initialValues).map((x) =>
                setInitialValues((state: any) => ({
                  ...state,
                  [`${x}`]: e.checked,
                })),
              );
            }
          }}
          checked={isAllSelected && isOneSelected}
        />
      ),
      sortable: false,
      filterable: false,
      render: (row: DuplicatedUsersRow) => (
        <Checkbox
          name={row.email}
          onChange={(e) =>
            setInitialValues((state: any) => ({
              ...state,
              [`${e.target.name}`]: e.checked,
            }))
          }
          checked={initialValues[row.email]}
        />
      ),
    },
    {
      field: 'email',
      className: 'vertical-top',
      header: t('generic.email'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: DuplicatedUsersRow) => (
        <FlexContainer direction="column" align="flex-start">
          <div className="group-row">{row.email}</div>
          <div className="secondary-text">
            {row.users?.length ? row.users.length : 0} {t('users')}
          </div>
        </FlexContainer>
      ),
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: DuplicatedUsersRow) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            {row.users?.length ? (
              row.users.map((user: User) => (
                <div className="group-row" key={user.id}>
                  {user.username}
                </div>
              ))
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </FlexContainer>
        );
      },
    },
    {
      field: 'name',
      header: t('generic.name'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: DuplicatedUsersRow) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            {row.users?.length ? (
              row.users.map((user: User) => (
                <div className="group-row" key={user.id}>
                  {user.name}
                </div>
              ))
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </FlexContainer>
        );
      },
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: DuplicatedUsersRow) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            {row.users?.length ? (
              row.users.map((user: User) => (
                <div className="group-row" key={user.id}>
                  {user.role.name}
                </div>
              ))
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </FlexContainer>
        );
      },
    },
  ];

  const toolbar = (
    <FlexContainer align="flex-start" direction="column" className="w-full">
      <StyledTitle className="mt-0 mb-2 p-1">
        {`${t('account.migration.header')} (${t('migration.stepThree')}) `}
      </StyledTitle>
      <DataTableToolbar>
        <FlexContainer
          justify="space-between"
          gap={8}
          align="flex-start"
          wrap="wrap"
        >
          <DataTableFilters
            filters={getFiltersFromColumns(columns)}
            onFilter={onFilter}
            defaultValues={{
              name: ' ',
            }}
          />
          {can(Actions.UPDATE, Subjects.BRANCHES) && (
            <AppButton
              isDisabled={
                (!activePhishingOnlyUsers?.count && !isOneSelected) ||
                (account?.isSystem &&
                  currentUser?.role.code !== SystemRoles.DEVELOPER)
              }
              label={
                (isOneSelected && duplicatedUsers?.count !== 0) ||
                !activePhishingOnlyUsers?.count
                  ? t('button.migrate')
                  : t('button.deactivate.phishingOnly')
              }
              severity="secondary"
              onClick={() => handleUsersMigration(initialValues)}
            />
          )}
        </FlexContainer>
      </DataTableToolbar>
    </FlexContainer>
  );

  return (
    <>
      {isPhishingOnlyUsersLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && account && (
            <>
              <AppBreadCrumb model={pathItems(account, currentAccount, t)} />
              <AccountHeader selectedAccount={account} />
            </>
          )}
          {notSyncCoursesMessage?.length !== 0 && (
            <Message
              severity="info"
              text={
                <FlexContainer justify="space-between">
                  <div>
                    {t('courses.efront.unavailable', {
                      courses: notSyncCoursesMessage.join(', '),
                    })}
                  </div>
                  <AppButton
                    icon="pi pi-times"
                    type="text"
                    severity="secondary"
                    className="ml-1 p-0 h-auto"
                    onClick={() => setNotSyncCoursesMessage([])}
                  />
                </FlexContainer>
              }
              className="mb-4"
            />
          )}
          <StyledCardsWrapper>
            <AccountCard
              title={`${t('account.migration')} (${t('migration.stepOne')})`}
              content={t('account.migration.content')}
              footer={
                <>
                  <AppButton
                    severity="secondary"
                    icon="pi pi-sync"
                    size="sm"
                    type="text"
                    className="ml-1 p-0 h-auto mb-4"
                    label={t('account.migration.syncEfrontCourses')}
                    state={efrontSyncLoading}
                    onClick={handleEfrontCoursesReSync}
                    isDisabled={
                      account?.isSystem &&
                      currentUser?.role.code !== SystemRoles.DEVELOPER
                    }
                  />

                  <div className="flex align-items-center mt-auto">
                    <Checkbox
                      checked={!!account?.meta?.isMigratedOutsideOfEFront}
                      name="outsideOfEfront"
                      onChange={handleAccountMigration}
                      disabled={
                        efrontSyncLoading === LoadingStatuses.LOADING ||
                        (account?.isSystem &&
                          currentUser?.role.code !== SystemRoles.DEVELOPER)
                      }
                    />
                    <StyledLabel htmlFor="outsideOfEfront" className="ml-2">
                      {t('account.isMigratedOutsideOfEFront')}
                    </StyledLabel>
                  </div>
                </>
              }
            />
            <AccountCard
              title={`${t('account.migration.deactivateUsers.header')} (${t(
                'migration.stepTwo',
              )})`}
              content={t('account.migration.deactivateUsers.content')}
              footer={
                <StyledSpan
                  id="button-wrapper-deactivateUsers"
                  className="mt-auto"
                >
                  <AppButton
                    type="outlined"
                    label={t('account.migration.deactivateUsers')}
                    className="w-full"
                    onClick={handleDeactivateEfrontUsers}
                    state={deactivationLoading}
                    isDisabled={
                      !account?.meta.isMigratedOutsideOfEFront ||
                      (account?.isSystem &&
                        currentUser?.role.code !== SystemRoles.DEVELOPER)
                    }
                  />
                  {!account?.meta.isMigratedOutsideOfEFront && (
                    <Tooltip
                      position="mouse"
                      target="#button-wrapper-deactivateUsers"
                      content={t('account.migration.disabled.tooltip')}
                    />
                  )}
                </StyledSpan>
              }
            />
            <AccountCard
              title={`${t('system.email.migrated.users')} (${t(
                'migration.stepFour',
              )})`}
              content={
                <>
                  {t('system.email.migrated.users.send')}
                  <StyledHoverText
                    onMouseOver={(e) => op.current?.show(e, e.target)}
                    onMouseLeave={() => op.current?.hide()}
                  >
                    {t('system.email.migrated.users.steps')}
                  </StyledHoverText>
                  <StyledOverlayPanel ref={op}>
                    {t('system.email.migrated.users.beforeProceeding')}
                    <ul>
                      <li>
                        {t('system.email.migrated.users.beforeProceeding.p1')}
                      </li>
                      <li>
                        {t('system.email.migrated.users.beforeProceeding.p2')}
                      </li>
                      <li>
                        {t('system.email.migrated.users.beforeProceeding.p3')}
                      </li>
                    </ul>
                  </StyledOverlayPanel>
                </>
              }
              footer={
                <StyledSpan id="button-wrapper" className="mt-auto">
                  <AppButton
                    type="outlined"
                    label={t('system.email.sendEmails')}
                    className="w-full mt-auto"
                    isDisabled={
                      account?.meta?.isMigrationTriggered ||
                      !account?.meta.isMigratedOutsideOfEFront ||
                      (account?.isSystem &&
                        currentUser?.role.code !== SystemRoles.DEVELOPER)
                    }
                    onClick={handleSendEmails}
                    state={
                      accountIsLoading && !account?.meta?.isMigrationTriggered
                        ? LoadingStatuses.LOADING
                        : LoadingStatuses.IDLE
                    }
                  />
                  {(account?.meta?.isMigrationTriggered ||
                    !account?.meta?.isMigratedOutsideOfEFront) && (
                    <Tooltip
                      position="mouse"
                      target="#button-wrapper"
                      content={
                        account?.meta?.isMigrationTriggered
                          ? t('system.email.migrated.users.already.send')
                          : t('account.migration.disabled.tooltip')
                      }
                    />
                  )}
                </StyledSpan>
              }
            />
          </StyledCardsWrapper>

          <DataTable
            classNames="multiline-datatable"
            data={duplicatedUsers?.result || undefined}
            count={duplicatedUsers?.count}
            isLoading={duplicateUsersAreLoading}
            toolbar={toolbar}
            columns={columns}
            rows={take}
            skip={skip}
            onPage={onPage}
            onSort={onSort}
            sort={sort}
            footer={t('account.migration.text')}
          />
        </>
      )}
    </>
  );
};
