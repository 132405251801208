import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountHeader } from './AccountHeader';

const StyledLink = styled.a`
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  text-decoration: none;
  color: var(--red-main);

  &:hover {
    color: var(--red-dark);
  }
`;

const getPathItems = (
  account: Account,
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  items.push(
    {
      label: t('integrations'),
      url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('gophish'),
      url: RedirectPaths[RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_GO_PHISH](
        account?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
  );

  return items;
};

export const AccountGoPhish: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { account, isLoading } = useAccount({ accountId: id });

  const toast = useToast();
  const navigate = useNavigate();

  const copyValue = async (value: string, type: string) => {
    await navigator.clipboard.writeText(value);

    toast?.success(
      t('gophish.copied'),
      t('gophish.copy.success', { type: type }),
    );
  };

  return (
    <>
      {isLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {account && currentAccount && (
            <>
              <AppBreadCrumb model={getPathItems(account, currentAccount, t)} />
              <AccountHeader selectedAccount={account} />
            </>
          )}

          <h3 className="mt-4 mb-4">{t('gophish.credentials')}</h3>

          {account && account.meta && account.meta.goPhishPassword ? (
            <FlexContainer
              width="50%"
              direction="column"
              align="flex-start"
              gap={10}
            >
              <FlexContainer>
                <InputText
                  className="w-full"
                  value={account.id}
                  disabled
                  autoComplete="off"
                />
                {(!account?.isSystem ||
                  currentUser?.role.code === SystemRoles.DEVELOPER) && (
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3"
                    onClick={() => copyValue(account.id, 'username')}
                  />
                )}
              </FlexContainer>
              <FlexContainer justify="flex-start">
                <Password
                  className="w-full"
                  value={account.meta.goPhishPassword}
                  toggleMask={
                    !account?.isSystem ||
                    currentUser?.role.code === SystemRoles.DEVELOPER
                  }
                  disabled
                  autoComplete="off"
                />
                {(!account?.isSystem ||
                  currentUser?.role.code === SystemRoles.DEVELOPER) && (
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3"
                    onClick={() =>
                      copyValue(account.meta.goPhishPassword, 'password')
                    }
                  />
                )}
              </FlexContainer>
              <StyledLink
                href={account.meta.goPhishURL}
                target="_blank"
                className="mt-2"
                rel="noopener noreferrer"
              >
                {t('gophish.goTo')}
                <i className="pi pi-external-link x2 ml-2" />
              </StyledLink>
              <AppButton
                type="outlined"
                label={t('button.back')}
                icon="pi pi-arrow-left"
                iconPos="left"
                onClick={() =>
                  navigate(
                    RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](
                      id as string,
                    ),
                  )
                }
                className="mt-2"
              />
            </FlexContainer>
          ) : (
            <p>{t('gophish.noAccount')}</p>
          )}
        </>
      )}
    </>
  );
};
