import { FilePurpose } from '@/api/enums';
import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { MaterialType } from '@/client/courses';
import { File } from '@/client/files';
import {
  COURSE_FILE_MAX_SIZE,
  COURSE_THUMBNAIL_MAX_SIZE,
} from '@/common/constants/files';
import { AppButton } from '@/ui/buttons';
import { FileUpload, FileUploadUploadEvent } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledUploadWrapper = styled(FlexContainer)`
  background: var(--beige-main);
  border-radius: 4px;
  min-width: 200px;
  min-height: 90px;
  padding: var(--small-padding);
  word-break: break-word;
  .icon {
    color: var(--gray-darker);
    font-size: var(--body-font-size);
  }

  .p-progress-spinner {
    width: 24px;
    height: 24px;
  }
`;

const StyledError = styled.p`
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 0;
  width: 100%;
`;

type CourseFileUploadProps = {
  preselectedFile?: File | null;
  type: MaterialType;
  onFileUpload: (file: File | null) => void;
  isDisabled?: boolean;
  setIsTabsDisabled: (isUploading: boolean) => void;
};

export const CourseFileUpload: React.FC<CourseFileUploadProps> = ({
  preselectedFile,
  type,
  onFileUpload,
  isDisabled = false,
  setIsTabsDisabled,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isKeycloakAuth = isKeycloakEnabled();
  const [error, setError] = useState<string[]>([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isMaterial =
    type === MaterialType.CUSTOM_MATERIAL ||
    type === MaterialType.POSTER ||
    type === MaterialType.RESOURCE;
  const isCustomMaterial = type === MaterialType.CUSTOM_MATERIAL;
  const isPoster = type === MaterialType.POSTER;

  useEffect(() => {
    setSelectedFile(preselectedFile || null);
  }, [preselectedFile]);

  const onUpload = async (e: FileUploadUploadEvent) => {
    const response = JSON.parse(e?.xhr?.response);

    setSelectedFile(response);

    setError([]);

    onFileUpload(response);
    setIsUploading(false);
    setIsTabsDisabled(false);
  };

  const clearUpload = () => {
    setSelectedFile(null);
    onFileUpload(null);
    setError([]);
  };

  return (
    <FlexContainer gap={24} justify="space-between" wrap="wrap">
      <StyledUploadWrapper flex="1">
        {selectedFile?.id && !isUploading && (
          <FlexContainer justify="space-between">
            <p>{selectedFile?.fileName}</p>
            {!isDisabled && (
              <AppButton
                type="text"
                severity="secondary"
                icon="pi pi-times"
                onClick={clearUpload}
              />
            )}
          </FlexContainer>
        )}
        {!selectedFile?.id && !isUploading && (
          <i className="pi pi-upload icon"></i>
        )}
        {isUploading && <ProgressSpinner />}
      </StyledUploadWrapper>
      {!isDisabled && (
        <FileUpload
          mode="basic"
          name="file"
          url={client.files.getFileUploadUrl()}
          accept={
            isMaterial
              ? isPoster
                ? '.pdf, .jpeg, .png'
                : isCustomMaterial
                ? '.pdf'
                : '.xlsx, .pdf'
              : '.zip'
          }
          maxFileSize={
            isPoster ? COURSE_THUMBNAIL_MAX_SIZE : COURSE_FILE_MAX_SIZE
          }
          onUpload={onUpload}
          onValidationFail={(file) => {
            if (
              (isPoster && file.size >= COURSE_THUMBNAIL_MAX_SIZE) ||
              file.size >= COURSE_FILE_MAX_SIZE
            ) {
              setError((state) => [
                t('error.fileSize', {
                  mb:
                    (isPoster
                      ? COURSE_THUMBNAIL_MAX_SIZE
                      : COURSE_FILE_MAX_SIZE) /
                    (1000 * 1000),
                }),
                ...state,
              ]);
            }
          }}
          onError={(event) => {
            const response = JSON.parse(event?.xhr?.response);

            if (response?.message) {
              setError((state) => [response.message, ...state]);
            }
            setIsUploading(false);
            setIsTabsDisabled(false);
          }}
          auto
          chooseLabel={t('generic.upload')}
          chooseOptions={{ icon: 'none' }}
          withCredentials
          onBeforeSend={(event) => {
            if (user && isKeycloakAuth) {
              event.xhr.setRequestHeader(
                'Authorization',
                `Bearer ${user?.access_token}`,
              );
            }
          }}
          onBeforeUpload={(event) => {
            clearUpload();
            setIsUploading(true);
            setIsTabsDisabled(true);
            isMaterial
              ? event.formData.append('purpose', FilePurpose.MATERIAL)
              : event.formData.append('purpose', FilePurpose.COURSE);
          }}
          disabled={isUploading}
        />
      )}
      {!!error?.length && (
        <StyledError>
          {error.map((error, index) => (
            <span key={index}>
              {error}.<br />
            </span>
          ))}
        </StyledError>
      )}
    </FlexContainer>
  );
};
