import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Group } from '@/client/groups';
import { getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useDeleteGroup, useGroups } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const GroupsPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const [deletingGroupId, setDeletingGroupId] = useState<string | null>(null);

  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, groups, refetch } = useGroups({
    accountId: account?.id,
    take,
    skip,
    filters: apiFilters,
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });
  const deleteGroup = useDeleteGroup();

  const menuItems = (group: Group) => {
    const menu: MenuItem[] = [
      {
        label: t('users'),
        icon: 'pi pi-users',
        command: () =>
          navigate(RedirectPaths[RedirectPathsEnum.GROUPS_USERS](group.id)),
      },
    ];

    if (
      can(Actions.UPDATE, Subjects.GROUPS) &&
      (!account?.isSystem || currentUser?.role.code === SystemRoles.DEVELOPER)
    ) {
      menu.push(
        ...[
          {
            items: [
              {
                label: t('generic.edit'),
                icon: 'pi pi-pencil',
                command: () => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.GROUPS_EDIT](group.id),
                  );
                },
              },
              ...(group?.isForPhishing &&
              canUseFeature(Subjects.PHISHING_CAMPAIGN_USER_TAGGING)
                ? []
                : [
                    {
                      label: t('generic.delete'),
                      icon: 'pi pi-times',
                      command: () =>
                        setDialogData({
                          type: 'confirmation',
                          show: true,
                          closeImmediatelyAfterAccept: true,
                          header: t('dialog.delete'),
                          message: t('dialog.delete.group'),
                          onAccept: async () =>
                            handleDeleteUserGroups(group.id),
                        }),
                    },
                  ]),
            ],
          },
        ],
      );
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: Group) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'autoAssign',
      header: t('group.autoAssign'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT_YES_NO,
        placeholder: t('generic.select'),
      },
      render: (row: Group) =>
        row.autoAssign ? (
          <AppChip label={t('dialog.yes')} type="primary" />
        ) : (
          <AppChip label={t('dialog.no')} type="secondary" />
        ),
    },
    ...(canUseFeature(Subjects.PHISHING_CAMPAIGN_USER_TAGGING)
      ? [
          {
            field: 'isForPhishing',
            header: t('group.isForPhishing'),
            sortable: false,
            filterable: true,
            filters: {
              type: FilterTypeEnum.SELECT_YES_NO,
              placeholder: t('generic.select'),
            },
            render: (row: Group) =>
              row.isForPhishing ? (
                <AppChip label={t('dialog.yes')} type="primary" />
              ) : (
                <AppChip label={t('dialog.no')} type="secondary" />
              ),
          },
        ]
      : []),

    {
      field: 'actions',
      header: t('generic.actions'),
      filterable: false,
      sortable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: Group) => {
        if (deletingGroupId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const handleDeleteUserGroups = async (groupId: string) => {
    setDeletingGroupId(groupId);
    try {
      await deleteGroup.delete({
        accountId: (account as Account)?.id,
        groupId,
      });
      await refetch();

      toast?.success(t('toast.success'), t('group.deleted'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setDeletingGroupId(null);
    }
  };

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.GROUPS_EDIT](e.data.id));

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
        />
        {can(Actions.CREATE, Subjects.GROUPS) &&
          (!account?.isSystem ||
            currentUser?.role.code === SystemRoles.DEVELOPER) && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() => {
                navigate(RedirectPaths[RedirectPathsEnum.GROUPS_CREATE]());
              }}
            />
          )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.GROUPS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const pathItems = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('groups'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <h1>{t('groups')}</h1>
      <DataTable
        data={groups?.result}
        count={groups?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
