import { FilePurpose } from '@/api/enums';
import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { File } from '@/client/files';
import { COURSE_THUMBNAIL_MAX_SIZE } from '@/common/constants/files';
import { AppButton } from '@/ui/buttons';
import { FileUpload, FileUploadUploadEvent } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  padding: var(--default-padding);
  margin-top: 6px;
`;

const StyledUploadWrapper = styled(FlexContainer)`
  background: var(--beige-main);
  border-radius: 4px;
  min-width: 200px;
  min-height: 90px;
  padding: var(--small-padding);
  word-break: break-word;
  .icon {
    color: var(--gray-darker);
    font-size: var(--body-font-size);
  }

  .p-progress-spinner {
    width: 24px;
    height: 24px;
  }
`;

const StyledThumbnail = styled(FlexContainer)`
  overflow: hidden;
  border-radius: 4px;

  img {
    max-width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
  }
`;

const StyledError = styled.p`
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 0;
  width: 100%;
`;

const StyledLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  font-weight: 500;
`;

type ImageUploadProps = {
  label: string;
  tooltip?: string;
  preselectedFile?: File | null;
  onFileUpload: (file: File | null) => void;
  setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
  purpose: FilePurpose;
  isDisabled?: boolean;
  required?: boolean;
  hasError?: boolean;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  label,
  tooltip,
  preselectedFile,
  onFileUpload,
  setIsSubmitDisabled,
  purpose,
  isDisabled,
  required,
  hasError,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isKeycloakAuth = isKeycloakEnabled();
  const [error, setError] = useState<string[]>([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (preselectedFile) {
      setSelectedFile(preselectedFile);
    }
  }, [preselectedFile]);

  const onUpload = async (e: FileUploadUploadEvent) => {
    const response = JSON.parse(e.xhr.response);

    setSelectedFile(response);

    setError([]);

    onFileUpload(response);
    changeUploadingState(false);
  };

  const clearUpload = () => {
    setSelectedFile(null);
    onFileUpload(null);
    setError([]);
  };

  const changeUploadingState = (isUploading: boolean) => {
    setIsUploading(isUploading);
    setIsSubmitDisabled(isUploading);
  };

  return (
    <div>
      <StyledLabel className={hasError ? 'p-error' : undefined}>
        {label} {tooltip ? <span className="red ml-1"> *</span> : ''}
        {tooltip && (
          <>
            <i className="pi pi-info-circle ml-2" />
            <Tooltip
              target=".pi-info-circle"
              content={tooltip}
              my="center bottom-20"
            />
          </>
        )}
      </StyledLabel>
      <StyledFlexContainer gap={24} justify="space-between" wrap="wrap">
        <StyledUploadWrapper className="flex-1">
          {selectedFile?.id && !isUploading && (
            <FlexContainer justify="space-between">
              <StyledThumbnail flex="1">
                <img
                  src={selectedFile?.signedUrl}
                  alt={selectedFile?.fileName}
                />
              </StyledThumbnail>
              {!isDisabled && (
                <AppButton
                  type="text"
                  severity="secondary"
                  icon="pi pi-times"
                  onClick={clearUpload}
                />
              )}
            </FlexContainer>
          )}
          {!selectedFile?.id && !isUploading && (
            <i className="pi pi-upload icon"></i>
          )}
          {isUploading && <ProgressSpinner />}
        </StyledUploadWrapper>
        {!isDisabled && (
          <FileUpload
            mode="basic"
            name="file"
            url={client.files.getFileUploadUrl()}
            accept=".png,.jpg,.jpeg,.svg"
            maxFileSize={COURSE_THUMBNAIL_MAX_SIZE}
            onUpload={onUpload}
            auto
            chooseLabel={t('generic.add.image')}
            chooseOptions={{ icon: 'none' }}
            withCredentials
            onValidationFail={(file) => {
              if (file.size >= COURSE_THUMBNAIL_MAX_SIZE) {
                setError((state) => [
                  t('error.fileSize', {
                    mb: COURSE_THUMBNAIL_MAX_SIZE / (1000 * 1000),
                  }),
                  ...state,
                ]);
              }
            }}
            onError={(event) => {
              const response = JSON.parse(event?.xhr?.response);

              if (response?.message) {
                setError((state) => [response.message, ...state]);
              }
              changeUploadingState(false);
            }}
            onBeforeSend={(event) => {
              if (user && isKeycloakAuth) {
                event.xhr.setRequestHeader(
                  'Authorization',
                  `Bearer ${user?.access_token}`,
                );
              }
            }}
            onBeforeUpload={(event) => {
              clearUpload();
              changeUploadingState(true);
              event.formData.append('purpose', purpose);
            }}
          />
        )}
        {!!error?.length && (
          <StyledError>
            {error.map((error, index) => (
              <span key={index}>
                {error}.<br />
              </span>
            ))}
          </StyledError>
        )}
      </StyledFlexContainer>
    </div>
  );
};
