import { SystemRoles } from '@/client/users';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUserState } from '@/store/features/users';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

export const AppHubspotListener: React.FC = () => {
  const { user } = useAppSelector(selectCurrentUserState);

  if (
    !process.env.REACT_APP_HUBSPOT_EMBED_SCRIPT_URL ||
    (user?.role?.code === SystemRoles.LEARNER &&
      !user?.account?.freeTrialEndsAt)
  ) {
    return <Outlet />;
  }

  const sendPageView = () => {
    const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
  };

  useEffect(() => {
    const existingScript = document.getElementById('hs-script-loader');

    if (!existingScript && process.env.REACT_APP_HUBSPOT_EMBED_SCRIPT_URL) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = process.env.REACT_APP_HUBSPOT_EMBED_SCRIPT_URL;

      document.body.appendChild(script);

      sendPageView();

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    sendPageView();
  }, [location]);

  return <Outlet />;
};
