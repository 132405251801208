import { Subjects } from '@/client/users';
import { AwarenessReportDatatable } from '@/components/reports/datatables';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { selectCurrentAccount } from '@/store/features/account';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';

export const AwarenessReportsPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { pendoTrack } = usePendo();
  const { canUseFeature } = useFeatureFlag();

  const { track } = useMixpanel();

  useEffect(() => {
    if (canUseFeature(Subjects.EVENT_TRACKING) && (mixpanel as any)?.__loaded) {
      pendoTrack(pendoEvent.awarenessReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
      });

      track('View Awareness reports');
    }
  }, [(mixpanel as any)?.__loaded]);

  return <AwarenessReportDatatable />;
};
