import { equal, handleAxiosError, like } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  ChangeEnrollDateFormValues,
  CourseAccount,
  CourseEnrollmentTypeEnum,
  CourseEntityEnrollEnum,
  CourseEntityScheduleEnum,
  CourseScheduleType,
  DeleteScheduleRequest,
  EnrollFormValues,
  MaterialType,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import { EnrollModal } from '@/components/courses/modals/EnrollModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useAccountsSchedules,
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useCourse,
  useCourseAccounts,
  useDeleteEnrollment,
  useDeleteSchedule,
  useMakeAccountsAvailable,
  useMakeAccountsUnavailable,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, usePendo } from '@/hooks/usePendo';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  calculateDayDifferenceFromToday,
} from '@/utils/helpers';
import {
  HubspotProperty,
  hubspotTrack,
  HubspotValue,
} from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CourseAccountsPage: React.FC<{ type: MaterialType }> = ({
  type,
}) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();
  const { track } = useMixpanel();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const { pendoTrackEnrollment } = usePendo();
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const isBranchAdmin = branchAdminCheck(user, account);
  const isMaterial = type !== MaterialType.COURSE;
  const isPoster = type === MaterialType.POSTER;

  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: courseId,
  });

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [selectedAccount, setSelectedAccount] = useState<
    CourseAccount | undefined
  >(undefined);
  const [showChangeEnrollDateModal, setShowChangeEnrollDateModal] =
    useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const {
    isLoading: isCourseAccountsLoading,
    courseAccounts,
    refetch,
  } = useCourseAccounts(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length ? [like('name', multiSearchValue)] : []),
        ...(!account?.isSystem ? [equal('id', account?.id)] : []),
      ],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
      enabled: true,
    },
    courseId,
  );

  const handlePendoTrackEnrollment = (
    type: 'create' | 'update' | 'delete',
    account: CourseAccount,
    date?: Date | null,
    autoEnroll?: boolean,
  ) => {
    if (course && account) {
      pendoTrackEnrollment({
        eventType: pendoEvent.enrollAccount,
        type,
        courses: [course],
        accounts: [account],
        date,
        oldEnrollDate:
          type === 'update' ? selectedAccount?.schedule?.date : null,
        autoEnroll,
      });
    }
  };

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedAccount && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedAccount?.schedule?.id,
          scheduleType: CourseEntityScheduleEnum.ACCOUNT,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
          // Track Mixpanel course enrollment with type update
          track('Update course enrollment', {
            Entity: 'Account',
            'Course id': course?.id,
            'Course name': course?.name,
            'Old enroll date (UTC)': moment
              .utc(selectedAccount?.schedule?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'New enroll date (UTC)': moment
              .utc(data?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enrolled to': selectedAccount?.name,
            'Account name': account?.name,
            'Total number of days': calculateDayDifferenceFromToday(data?.date),
            'Current date (UTC)': moment
              .utc()
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enroll type': selectedAccount?.schedule?.autoEnroll
              ? 'auto'
              : 'manual',
          });

          handlePendoTrackEnrollment(
            'update',
            selectedAccount,
            data.date,
            selectedAccount?.schedule?.autoEnroll,
          );
        }

        await refetch();
        setShowChangeEnrollDateModal(false);
        setSelectedAccount(undefined);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const changeScheduleEnrollType = useChangeScheduleEnrollType();
  const handleChangeScheduleEnrollType = async (
    courseAccount: CourseAccount,
  ) => {
    if (courseAccount?.schedule?.date) {
      try {
        await changeScheduleEnrollType.edit({
          scheduleId: courseAccount?.schedule.id,
          scheduleType: CourseEntityScheduleEnum.ACCOUNT,
          autoEnroll: !courseAccount?.schedule.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const saveAvailableAccounts = useMakeAccountsAvailable();
  const saveUnavailableAccounts = useMakeAccountsUnavailable();

  const handleSubmitAvailable = async (accountId: string) => {
    if (course) {
      try {
        await saveAvailableAccounts.add({
          courseId: course?.id,
          accounts: [accountId],
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.accounts.available.marked'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const handleSubmitUnavailable = async (accountId: string) => {
    if (course) {
      try {
        await saveUnavailableAccounts.remove({
          courseId: course?.id,
          accounts: [accountId],
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.accounts.unavailable.marked'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const accountsSchedules = useAccountsSchedules();
  const handleEnrollAccount = async (data: EnrollFormValues) => {
    if (course && selectedAccount) {
      try {
        await accountsSchedules.add({
          courses: [course.id],
          accounts: [selectedAccount.id],
          date: data.date,
          autoEnroll: data.autoEnroll,
          type: data.type,
        });

        if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
          handlePendoTrackEnrollment(
            'create',
            selectedAccount,
            data.date,
            data.autoEnroll,
          );
        }

        await refetch();
        toast?.success(t('toast.success'), t('courses.account.enroll.success'));

        if (user?.account?.freeTrialEndsAt) {
          hubspotTrack({
            [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
          });
        }
        setSelectedAccount(undefined);
        setShowEnrollModal(false);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteSchedule = useDeleteSchedule();
  const handleDeleteSchedule = async (payload: DeleteScheduleRequest) => {
    try {
      await deleteSchedule.delete(payload);
      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity: 'Account',
          'Course name': course?.name,
          'Course id': course?.id,
          'Enroll date (UTC)': moment
            .utc(payload?.entity?.schedule?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to': payload?.entity?.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': payload?.entity?.schedule?.autoEnroll
            ? 'auto'
            : 'manual',
        });

        handlePendoTrackEnrollment(
          'delete',
          payload.entity,
          payload.entity?.schedule?.date,
          payload.entity?.schedule?.autoEnroll,
        );
      }

      await refetch();
      toast?.success(
        t('toast.success'),
        t('courses.account.deleteSchedule.success.name', {
          name: account?.name,
        }),
      );

      setSelectedAccount(undefined);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const deleteEnrollment = useDeleteEnrollment();
  const handleDeleteEnrollment = async (
    courseId: string,
    courseAccount: CourseAccount,
  ) => {
    try {
      await deleteEnrollment.post({
        courseId,
        request: { [CourseEntityEnrollEnum.ACCOUNT]: [courseAccount.id] },
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity: 'Account',
          'Course name': course?.name,
          'Course id': course?.id,
          'Enroll date (UTC)': moment
            .utc(courseAccount?.schedule?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to': courseAccount.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': courseAccount.schedule?.autoEnroll ? 'auto' : 'manual',
        });

        handlePendoTrackEnrollment(
          'delete',
          courseAccount,
          courseAccount?.schedule?.date,
          courseAccount?.schedule?.autoEnroll,
        );
      }

      await refetch();
      toast?.success(t('toast.success'), t('courses.account.unenroll.success'));

      setSelectedAccount(undefined);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (courseAccount: CourseAccount) => {
    const menu: MenuItem[] = [];

    if (courseAccount.available) {
      menu.push({
        label: t('generic.enroll'),
        icon: 'pi pi-calendar-plus',
        command: () => {
          setSelectedAccount(courseAccount);
          setShowEnrollModal(true);
        },
      });
    }

    if (courseAccount?.schedule && courseAccount.available) {
      menu.push({
        label: courseAccount?.schedule?.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeScheduleEnrollType(courseAccount);
            },
          }),
      });
    }

    if (
      courseAccount.schedule &&
      !courseAccount.schedule.executedAt &&
      courseAccount.available
    ) {
      menu.push({
        label: t('courses.change.enrollDate'),
        icon: 'pi pi-calendar',
        command: () => {
          setSelectedAccount(courseAccount);
          setShowChangeEnrollDateModal(true);
        },
      });
    }

    if (account?.isSystem) {
      if (courseAccount.available) {
        menu.push({
          label: t('generic.accounts.unavailable'),
          icon: 'pi pi-pencil',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('generic.accounts.unavailable'),
              message: t('dialog.accounts.unavailable'),
              onAccept: async () =>
                await handleSubmitUnavailable(courseAccount.id),
            }),
        });
      } else {
        menu.push({
          label: t('generic.accounts.available'),
          icon: 'pi pi-pencil',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('generic.accounts.available'),
              message: t('dialog.accounts.available'),
              onAccept: async () =>
                await handleSubmitAvailable(courseAccount.id),
            }),
        });
      }

      if (
        courseAccount?.schedule?.date &&
        !courseAccount?.schedule?.executedAt
      ) {
        menu.push({
          label: t('generic.deleteSchedule'),
          icon: 'pi pi-calendar-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.deleteSchedule'),
              message: t('dialog.deleteSchedule.account', {
                name: courseAccount?.name,
              }),
              onAccept: async () => {
                await handleDeleteSchedule({
                  entity: courseAccount,
                  id: courseAccount?.schedule?.id,
                  source: CourseEntityScheduleEnum.ACCOUNT,
                });
              },
            }),
        });
      }
    }

    if (
      courseAccount?.schedule?.executedAt &&
      courseAccount.available &&
      courseAccount.branchesCount === courseAccount.enrolledBranchesCount &&
      courseAccount.usersCount === courseAccount.enrolledUsersCount
    ) {
      menu.push({
        label: t('generic.unenroll'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.deleteEnrollment'),
            message: t('dialog.deleteEnrollment.account', {
              name: courseAccount?.name,
            }),
            onAccept: async () => {
              if (!courseId) return;
              setSelectedAccount(courseAccount);
              await handleDeleteEnrollment(courseId, courseAccount);
            },
          }),
      });
    }
    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('account'),
      sortable: true,
      filterable: false,
      style: {
        minWidth: '200px',
      },
      render: (row: CourseAccount) => <div>{row?.name}</div>,
    },
    ...(isPoster
      ? []
      : ([
          {
            field: 'schedule.date',
            header: t('generic.enrollDate'),
            filterable: true,
            filters: {
              label: t('filter.byDate'),
              field: FilterNamesEnum.COURSE_ACCOUNT_UPCOMING_DATE,
              type: FilterTypeEnum.SELECT_UPCOMING_PAST,
              placeholder: t('filter.date'),
            },
            sortable: true,
            render: (row: CourseAccount) => (
              <>
                {row?.schedule?.date ? (
                  <FormatDate
                    format={DateFormats.TIMEDATE}
                    date={row?.schedule?.date}
                  />
                ) : (
                  <div>&#8212;</div>
                )}
              </>
            ),
          },

          {
            field: 'notEnrolledBranches',
            header: t('courses.accounts.notEnrolled.branches'),
            sortable: false,
            filterable: false,
            render: (row: CourseAccount) => (
              <div>{row?.branchesCount - row?.enrolledBranchesCount}</div>
            ),
          },
          ...((can(Actions.READ, Subjects.COURSE_GROUP_SCHEDULES)
            ? [
                {
                  field: 'notEnrolledGroups',
                  header: t('courses.accounts.notEnrolled.groups'),
                  sortable: false,
                  filterable: false,
                  render: (row: CourseAccount) => (
                    <div>{row?.groupsCount - row?.enrolledGroupsCount}</div>
                  ),
                },
                {
                  field: 'notEnrolledUsers',
                  header: t('courses.accounts.notEnrolled.users'),
                  sortable: false,
                  filterable: false,
                  render: (row: CourseAccount) => (
                    <div>{row?.usersCount - row?.enrolledUsersCount}</div>
                  ),
                },
              ]
            : []) as DataTableColumnType[]),
          {
            field: 'schedule.autoEnroll',
            header: t('generic.enrollType'),
            sortable: true,
            filterable: true,
            filters: {
              label: t('filter.byEnrollType'),
              field: FilterNamesEnum.COURSE_ENTITY_AUTO_ENROLL,
              type: FilterTypeEnum.SELECT_AUTO_MANUAL,
              placeholder: t('filter.enrollType'),
            },
            render: (row: CourseAccount) => {
              const enrollType = row?.schedule?.autoEnroll ? (
                <AppChip label={t('generic.auto')} type="primary" />
              ) : (
                <AppChip label={t('generic.manual')} type="secondary" />
              );
              return (
                <>
                  {row?.schedule && row.schedule.autoEnroll !== null ? (
                    <div>{enrollType}</div>
                  ) : (
                    <div>&#8212;</div>
                  )}
                </>
              );
            },
          },
        ] as DataTableColumnType[])),
    {
      field: 'available',
      header: t('courses.admin.access'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byAdminAccess'),
        type: FilterTypeEnum.SELECT_AVAILABLE_UNAVAILABLE,
        placeholder: t('filter.byAdminAccess'),
      },
      render: (row: CourseAccount) => {
        const available = row.available ? (
          <AppChip label={t('generic.available')} type="primary" />
        ) : (
          <AppChip label={t('generic.unavailable')} type="secondary" />
        );
        return <div>{available}</div>;
      },
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseAccount) => (
        <DataTableActions
          key={row.id}
          menuItems={menuItems(row)}
          disabled={menuItems(row).length < 1 || isBranchAdmin}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const toolbar = (
    <DataTableToolbar
      justify={account?.isSystem ? 'space-between' : 'flex-end'}
    >
      {account?.isSystem && (
        <>
          <DataTableFilters
            filters={getFiltersFromColumns(columns)}
            onFilter={onFilter}
            className="flex-initial"
            tableName={
              isMaterial
                ? TableNamesEnum.MATERIAL_ACCOUNTS
                : TableNamesEnum.COURSE_ACCOUNTS
            }
          />
          <div className="p-input-icon-left flex-auto min-w-300">
            <InputText
              className="w-full"
              onInput={debouncedSetMultiSearchValue}
              placeholder={t('accounts.search')}
              autoComplete="off"
            />
            <i className="pi pi-search" />
          </div>
        </>
      )}
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? isPoster
              ? TableNamesEnum.POSTER_ACCOUNTS
              : TableNamesEnum.MATERIAL_ACCOUNTS
            : TableNamesEnum.COURSE_ACCOUNTS
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>
            {isMaterial
              ? t('material.accounts.loading')
              : t('course.accounts.loading')}
          </h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && <CoursesTabs courseId={courseId} type={course.type} />}
          <DataTable
            dataKey="dataId"
            data={courseAccounts?.result.map((x) => ({
              ...x,
              dataId: `${x.id}-${x?.schedule?.id}`,
            }))}
            count={courseAccounts?.count as number}
            isLoading={isCourseAccountsLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
          />

          <EnrollModal
            type={CourseEnrollmentTypeEnum.ENROLL}
            account={selectedAccount as unknown as Account}
            enrollType={CourseEntityScheduleEnum.ACCOUNT}
            visible={showEnrollModal && !!selectedAccount}
            onSubmit={handleEnrollAccount}
            onHide={() => setShowEnrollModal(false)}
            course={course}
          />

          <ChangeEnrollDateModal
            date={selectedAccount?.schedule?.date ?? undefined}
            visible={showChangeEnrollDateModal && !!selectedAccount}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedAccount(undefined)}
          />
        </>
      )}
    </>
  );
};
