import { Campaign } from '@/client/campaigns';
import EmailPlaceholderImage from '@/images/placeholders/email-template.svg';
import { FlexContainer } from '@/ui/styled-ui';
import { Avatar } from 'primereact/avatar';
import { ProgressSpinner } from 'primereact/progressspinner';

import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

const StyledImgContainer = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-main);

  .img-container-email {
    width: 100%;
    height: auto;
    background: var(--white-main);
    padding-inline: var(--default-padding);
    img {
      pointer-events: none;
      width: 100%;
      height: auto;
      background: var(--white-main);
      margin: 0 auto;
    }
  }

  .buttons-container {
    padding: var(--default-padding);
    gap: var(--small-padding);
    display: flex;
    margin-top: auto;
  }
`;

const StyledAvatar = styled(FlexContainer)`
  padding: var(--default-padding);
  .p-avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--avatar-background);
    color: var(--avatar-text);
    font-weight: 500;
    .p-avatar-text {
      font-size: var(--medium-font-size);
      line-height: var(--medium-line-height);
    }
  }

  .styled-name {
    align-items: flex-start;
    padding-left: var(--small-padding);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 500 !important;

    h4 {
      margin: 0 !important;
    }

    .email {
      color: var(--gray-darker);
    }
  }
`;

type EmailTemplatePreviewProps = {
  campaign: Campaign;
  actions?: ReactNode;
  className?: string;
};

export const EmailTemplatePreview: React.FC<EmailTemplatePreviewProps> = ({
  campaign,
  actions,
  className,
}) => {
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <StyledImgContainer className={className}>
      <StyledAvatar justify="flex-start" align="flex-start">
        <Avatar
          label={(campaign.sendingProfile?.senderName?.length
            ? campaign.sendingProfile.senderName
            : campaign.sendingProfile.name
          )
            .slice(0, 1)
            .toUpperCase()}
          shape="circle"
        />
        <FlexContainer direction="column" className="styled-name">
          <h4>
            {campaign.sendingProfile?.senderName?.length
              ? campaign.sendingProfile.senderName
              : campaign.sendingProfile.name}
          </h4>
          <div className="email">{campaign.sendingProfile.fromAddress}</div>
        </FlexContainer>
      </StyledAvatar>
      <div className="img-container-email">
        <img
          src={
            campaign.emailTemplate?.preview?.signedUrl ||
            campaign.emailTemplate?.thumbnail?.signedUrl ||
            EmailPlaceholderImage
          }
          onLoad={() => setImgLoading(false)}
          alt={campaign.emailTemplate?.thumbnail?.fileName}
        />
        {imgLoading && (
          <FlexContainer direction="column" align="center" className="h-full">
            <ProgressSpinner />
          </FlexContainer>
        )}
      </div>
      {actions}
    </StyledImgContainer>
  );
};
