import { processListingParams } from '@/api/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import {
  UpdateAccountFeatureFlagsRequest,
  UpdateFeatureFlagsRequest,
} from './types';

export const featuresFlagsService = (client: AxiosInstance) => {
  const FEATURE_FLAGS_BASE_URL = 'feature-flags';
  //accounts/feature-flags

  const getFeatureFlags = async (params: any): Promise<any> => {
    try {
      const result = await client.get<any, AxiosResponse<any>>(
        `/${FEATURE_FLAGS_BASE_URL}`,
        {
          withCredentials: true,
          params: processListingParams(params),
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccountFeatureFlags = async (
    accountId: string | undefined,
    params: any,
  ): Promise<any> => {
    try {
      const result = await client.get<any, AxiosResponse<any>>(
        `/accounts/${accountId}/${FEATURE_FLAGS_BASE_URL}`,
        {
          withCredentials: true,
          params: processListingParams(params),
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateAccountFeatureFlags = async (
    request: UpdateAccountFeatureFlagsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `/accounts/${request.accountId}/${FEATURE_FLAGS_BASE_URL}`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateFeatureFlags = async (
    request: UpdateFeatureFlagsRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`/${FEATURE_FLAGS_BASE_URL}/accounts`, request, {
        withCredentials: true,
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getFeatureFlags,
    getAccountFeatureFlags,
    updateAccountFeatureFlags,
    updateFeatureFlags,
  };
};
