import { equal } from '@/api/helpers';
import { CampaignFormValues } from '@/client/campaigns';
import { Subjects, User } from '@/client/users';
import { EMAIL_REGEX } from '@/common/constants';
import { ExcludeFormValue, TargetFormValue } from '@/common/types';
import { FormikSwitch } from '@/components/form';
import {
  BranchesMultiselectInput,
  GroupsMultiselectInput,
} from '@/components/form/selectors';
import { UsersMultiselectInput } from '@/components/form/selectors/UsersMultiselectInput';
import { ShowData } from '@/components/show-data';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useTargetGroups } from '@/hooks/useTargetGroups';
import { selectCurrentAccount } from '@/store/features/account';
import { FlexContainer } from '@/ui/styled-ui';
import { TargetEntitiesSelector } from '@/ui/target-selector';
import { Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledSearchContainer = styled.div`
  margin-top: calc(21px + 0.5rem) !important;
`;

type TargetGroupStepProps = {
  values: CampaignFormValues;
  valuesSetter: (field: string, value: any) => void;
};

export const TargetGroupStep: React.FC<TargetGroupStepProps> = ({
  values,
  valuesSetter,
}) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();
  const account = useAppSelector(selectCurrentAccount);

  const [notUniqueEmails, setNotUniqueEmails] = useState<User[]>([]);
  const [invalidEmails, setInvalidEmails] = useState<User[]>([]);

  const {
    isLoading,
    branches,
    branchesUsers,
    groups,
    groupsUsers,
    users,
    usersList,
    searchedUsers,
    searchUserValue,
    onSearchInput,
    handleClearDuplicatingUsers,
    handleChangeBranches,
    handleChangeGroups,
    handleChangeUsers,
    handleChangeUsersList,
    handleRemoveUserFromUsersList,
    handleClearAll,
  } = useTargetGroups();

  useEffect(() => {
    handleChangeBranches([]);
    handleChangeGroups([]);
    handleChangeUsers([]);
    handleChangeUsersList(values.targetGroup?.usersList || []);
  }, []);

  useEffect(() => {
    valuesSetter('targetGroup', {
      branches,
      groups,
      users,
      usersList,
    });
  }, [branches, groups, users, usersList]);

  const combinedUsers = useMemo(handleClearDuplicatingUsers, [
    branchesUsers,
    groupsUsers,
  ]);

  // Triggers rerender so the users dropdown clear all the empty labels and users coming from branches and groups
  const usersAdditionalFilters = useMemo(
    () => [equal('account', account?.id), equal('active', true)],
    [combinedUsers],
  );

  useEffect(() => {
    const data = usersList
      .map((u) => ({
        ...u,
        email:
          u?.email.toLowerCase() === 'phishing_only@cyberpilot.io'
            ? u.username.toLowerCase()
            : u.email.toLowerCase(),
      }))
      .map((u, index, array) => ({
        ...u,
        isDuplicate:
          array.filter((e, i) => e.email === u.email && i !== index).length > 0,
      }));

    setInvalidEmails(data.filter((user) => !user.email.match(EMAIL_REGEX)));
    setNotUniqueEmails(data.filter((user) => user.isDuplicate));
  }, [usersList]);

  return (
    <>
      <h2>{t('campaign.select.target')}</h2>

      {canUseFeature(Subjects.PHISHING_CAMPAIGN_TARGET_SELECTOR) ? (
        <>
          <TargetEntitiesSelector
            className="phishing"
            title={t('campaign.target')}
            required
            includedEntities={values?.targets ?? []}
            setIncludedEntities={(values: TargetFormValue[]) =>
              valuesSetter('targets', values)
            }
            excludedEntities={values?.excludes ?? []}
            setExcludedEntities={(values: ExcludeFormValue[]) =>
              valuesSetter('excludes', values)
            }
          />
          <div className="field-checkbox mt-4">
            <Field
              inputId="useDynamicTarget"
              name="useDynamicTarget"
              label={t('campaign.dynamicTarget.enable')}
              component={FormikSwitch}
            />
          </div>
        </>
      ) : (
        <>
          {notUniqueEmails.length > 0 && (
            <FlexContainer
              gap={24}
              justify="flex-start"
              align="flex-start"
              className="mb-4"
            >
              <Message
                severity="error"
                text={t('campaign.errors.notUniqueEmails', {
                  users: notUniqueEmails.map((u) => u.username).join(', '),
                })}
              />
            </FlexContainer>
          )}

          {invalidEmails.length > 0 && (
            <FlexContainer
              gap={24}
              justify="flex-start"
              align="flex-start"
              className="mb-4"
            >
              <Message
                severity="error"
                text={t('campaign.errors.invalidEmails', {
                  users: invalidEmails.map((u) => u.username).join(', '),
                })}
              ></Message>
            </FlexContainer>
          )}

          <FlexContainer
            gap={24}
            justify="flex-start"
            align="flex-start"
            className="mb-4"
          >
            <FlexContainer direction="column" align="flex-start">
              <div className="field w-full">
                <label htmlFor="branches">{t('branches')}</label>
                <BranchesMultiselectInput
                  className="w-full"
                  onChange={handleChangeBranches}
                  additionalFilters={[equal('account', account?.id)]}
                  selectedOptions={branches}
                  data-testid="campaign-form-target-group-step-branches"
                  isDisabled={isLoading}
                  accountId={account?.id}
                />
              </div>
              <div className="field w-full">
                <label htmlFor="groups">{t('groups')}</label>
                <GroupsMultiselectInput
                  className="w-full"
                  onChange={handleChangeGroups}
                  additionalFilters={[equal('account', account?.id)]}
                  selectedOptions={groups}
                  data-testid="campaign-form-target-group-step-groups"
                  isDisabled={isLoading}
                  accountId={account?.id}
                />
              </div>
              <div className="field w-full">
                <label htmlFor="users">{t('users')}</label>
                <UsersMultiselectInput
                  className="w-full"
                  onChange={handleChangeUsers}
                  additionalFilters={usersAdditionalFilters}
                  selectedOptions={users}
                  data-testid="campaign-form-target-group-step-users"
                  isDisabled={isLoading}
                  accountId={account?.id}
                />
              </div>
            </FlexContainer>
            <StyledSearchContainer className="w-full">
              <div className="field w-full mb-3 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  placeholder={t('campaign.searchUser')}
                  className="w-full"
                  autoComplete="off"
                  onInput={onSearchInput}
                  data-testid="campaign-form-target-group-users-list-search"
                />
              </div>
              <ShowData
                loading={isLoading}
                data={searchUserValue ? searchedUsers : usersList}
                onRemoveAll={handleClearAll}
                onRemove={handleRemoveUserFromUsersList}
                data-testid="campaign-form-target-group-users-list-container"
              />
            </StyledSearchContainer>
          </FlexContainer>
        </>
      )}
    </>
  );
};
