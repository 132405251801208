import { contains, equal } from '@/api/helpers';
import {
  CourseStatusAttemptEnum,
  MaterialType,
  PosterCategory,
} from '@/client/courses';
import { useLearnerCourses } from '@/hooks/query';
import { PosterCard } from '@/ui/poster-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { postersColumnsWidth } from '@/utils/helpers';
import { isMobile } from '@/utils/helpers/ui.helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LearnerLayout } from '../LearnerLayout';
import { LearnerDashboardMaterialsTabs } from '../templates/LearnerDashboardMaterialsTabs';
import { PosterCategoriesTabs } from './PosterCategoriesTabs';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${postersColumnsWidth.THREE}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.TWO}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledEmptyMessage = styled.div`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
`;

const StyledPosterContainer = styled(FlexContainer)`
  border-radius: var(--medium-border-radius);
  padding: var(--medium-padding) var(--default-padding) var(--default-padding);
  background-color: var(--beige-main);
  min-height: 100%;
  flex-grow: 1;
  justify-content: flex-start;

  &.mobile-posters-container {
    padding: var(--medium-padding) !important;
    .p-tabmenu {
      margin-bottom: var(--medium-padding);
    }
  }
`;

export const LearnerDashboardPostersPage: React.FC<{
  category?: PosterCategory;
}> = ({ category }) => {
  const { t } = useTranslation();
  const { isLoading, learnerCourses: posters } = useLearnerCourses({
    skip: 0,
    type: CourseStatusAttemptEnum.IN_PROGRESS,
    filters: [
      equal('type', MaterialType.POSTER),
      ...(category ? [contains('categories', [category])] : []),
    ],
    staleTime: Infinity,
  });

  return (
    <LearnerLayout isLoading={isLoading}>
      <LearnerDashboardMaterialsTabs />
      <StyledPosterContainer
        direction="column"
        align="flex-start"
        className={isMobile() ? 'mobile-posters-container' : ''}
      >
        <PosterCategoriesTabs />
        {posters?.count ? (
          <StyledCardGridContainer>
            {posters?.result.map((poster) => (
              <PosterCard key={poster.id} poster={poster} />
            ))}
          </StyledCardGridContainer>
        ) : (
          <StyledEmptyMessage>
            {t('posters.category.noPosters')}
          </StyledEmptyMessage>
        )}
      </StyledPosterContainer>
    </LearnerLayout>
  );
};
