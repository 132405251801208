import Maintenance from '@/images/placeholders/maintenance.svg';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledImg = styled.img`
  max-width: 450px;
  width: 100%;
`;

const StyledFlexContainer = styled(FlexContainer)`
  padding: var(--default-padding);
  overflow-x: hidden;
  min-height: 100%;

  p {
    text-align: center;
    line-height: var(--medium-line-height);
    font-weight: 500;
    text-align: center;
    margin: 0;
  }
`;

export const AppMaintenance: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFlexContainer direction="column">
      <Helmet>
        <link rel="icon" href={Maintenance} />
        <link rel="apple-touch-icon" href={Maintenance} />
        <meta name="description" content={t('maintenance')} />
        <title>{t('maintenance')}</title>
      </Helmet>
      <h1>{t('maintenance.heading')}</h1>
      <p>
        {t('maintenance.paragraph.1')}
        <br />
        {t('maintenance.paragraph.2')}
      </p>
      <StyledImg src={Maintenance} alt={t('maintenance')} />
    </StyledFlexContainer>
  );
};
