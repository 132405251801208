import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AccountFeatureFlagsDatatable } from '@/components/feature-flags/datatables/AccountFeatureFlagsDatatable';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';

const pathItems = (
  account: Account,
  currentAccount: Account,
  t: (key: string, placeholder?: { name: string }) => string,
) => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }
  items.push({
    label: t('features'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_FEATURE_FLAGS](account?.id),
    template: AppBreadCrumbTemplate,
  });

  return items;
};

export const AccountFeaturesPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: accountId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { account } = useAccount({ accountId });

  return (
    <>
      {!currentAccount || !account ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <FlexContainer direction="column" align="flex-start">
          <div className="w-full">
            <AppBreadCrumb
              model={pathItems(
                account as Account,
                currentAccount as Account,
                t,
              )}
            />
            <AccountHeader selectedAccount={account} />
            <h1>{t('features')}</h1>
            <AccountFeatureFlagsDatatable />
          </div>
        </FlexContainer>
      )}
    </>
  );
};
