import { EntityEnum } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';
import { MultipleToastType, ToastType } from '@/hooks/useToast';

type ToastModalType = {
  success: (summary: string, detail: string, life?: number | undefined) => any;
  warning: (summary: string, detail: string, life?: number | undefined) => any;
  info: (summary: string, detail: string, life?: number | undefined) => any;
  error: (summary: string, detail: string, life?: number | undefined) => any;
  multiple: (toasts: MultipleToastType[]) => any;
};

export const parseImportNotification = (
  messages: any,
  type: EntityEnum,
  toast: ToastModalType | null,
  t: TranslationFunctionType,
) => {
  const imported = messages?.data?.payload?.successful;
  const total = messages?.data?.payload?.total;
  const statuses: {
    [key: string]: [ToastType | undefined, string, string];
  } = {
    [`${type}.users.import.finished`]: [
      imported > 0 ? toast?.success : toast?.error,
      imported > 0 ? t('toast.success') : t('toast.error'),
      imported > 0 && imported === total
        ? t('users.import.success', {
            total,
          })
        : imported > 0 && imported !== total
        ? t('users.import.partially.imported', { imported, total })
        : t('users.import.none.imported', { total }),
    ],
    [`${type}.users.import.failed`]: [
      toast?.error,
      t('toast.info'),
      t('users.import.fail'),
    ],
  };

  const [callback, title, description] = statuses[messages.data.event];

  callback && callback(title, description);
};

export const parseAccountImportNotification = (
  messages: any,
  toast: ToastModalType | null,
  t: TranslationFunctionType,
) => {
  const imported = messages?.data?.payload?.successful;
  const total = messages?.data?.payload?.total;
  const statuses: {
    [key: string]: [ToastType | undefined, string, string];
  } = {
    ['account.users.import.finished']: [
      toast?.success,
      t('toast.success'),
      t('users.import.success', {
        total,
      }),
    ],
    ['account.users.import.failed']: [
      toast?.error,
      t('toast.info'),
      t('users.import.fail'),
    ],
    ['account.users.import.warning']: [
      imported > 0 ? toast?.success : toast?.error,
      imported > 0 ? t('toast.success') : t('toast.error'),
      imported > 0
        ? t('users.import.partially.imported', { imported, total })
        : t('users.import.none.imported', { total }),
    ],
  };

  const [callback, title, description] = statuses[messages.data.event];

  callback && callback(title, description);
};

export const parseImportWarnings = (
  messageErrors: any,
  t: TranslationFunctionType,
): MultipleToastType[] => {
  const errors = [] as MultipleToastType[];

  // Use setTimeout to delay error toast
  Object.values(messageErrors).forEach((row: any) => {
    const rowErrors: string[] = [];

    Object.values(row.errors).forEach((x: any) => rowErrors.push(x.join(', ')));

    errors.push({
      severity: 'info',
      summary: t('toast.info'),
      detail: t('users.import.failed.row', {
        row: row.rowNumber,
        errors: rowErrors.join(', '),
      }),
      life: 15000,
    });
  });

  return errors;
};

export const getUserExportColumns = (visibleFields: string[]) =>
  visibleFields.filter((field) => !['actions', 'updated'].includes(field));
