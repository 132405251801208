import {
  Campaign,
  CreateCampaignFromTemplateFormState,
  ListCampaignsResponse,
} from '@/client/campaigns';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import {
  displaySenderProfile,
  ITEMS_PER_TEMPLATES_MODAL,
} from '@/utils/helpers';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult } from 'react-query';
import styled from 'styled-components';
import { ThumbnailsGenerationNotify } from '../thumbnails';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CampaignTemplatesListPropsType = {
  values: CreateCampaignFromTemplateFormState;
  setFieldValue: (field: string, value: any) => void;
  campaigns: ListCampaignsResponse;
  refetch: () => Promise<QueryObserverResult<ListCampaignsResponse, unknown>>;
  skip: number;
  onPageChange: (value: number) => void;
  onRowsChange: (value: number) => void;
  count: number;
};

export const CampaignTemplatesList: React.FC<
  CampaignTemplatesListPropsType
> = ({
  values,
  setFieldValue,
  campaigns,
  refetch,
  skip,
  onPageChange,
  onRowsChange,
  count,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ThumbnailsGenerationNotify
        refetch={refetch}
        templates={campaigns.result.map(
          (campaign: Campaign) => campaign.emailTemplate,
        )}
      />
      <FlexContainer
        direction="column"
        justify="space-between"
        className="h-full"
      >
        <StyledCardGridContainer>
          {campaigns?.result.map((campaign: Campaign) => (
            <ImageCard
              isLoading={
                campaign.emailTemplate.thumbnailState ===
                ThumbnailState.PROCESSING
              }
              key={campaign.id}
              id={campaign.id}
              campaignValue={campaign}
              title={campaign.name}
              interactionType="withSelect"
              thumbnail={campaign.emailTemplate?.thumbnail?.signedUrl}
              author={displaySenderProfile(t, campaign.sendingProfile.name)}
              templateType="email"
              selectName={campaign.name}
              isSelected={values.selectedCampaign?.id === campaign.id}
              onChange={(event) =>
                setFieldValue('selectedCampaign', event.value)
              }
              additionalClass="gray"
            />
          ))}
        </StyledCardGridContainer>
        {count > ITEMS_PER_TEMPLATES_MODAL && (
          <Paginator
            className="justify-content-end pb-0"
            rows={ITEMS_PER_TEMPLATES_MODAL}
            first={skip}
            totalRecords={count}
            pageLinkSize={3}
            onPageChange={(event: PaginatorPageChangeEvent) => {
              onRowsChange(event.rows);
              onPageChange(event.first);
            }}
            template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
              'filter.of',
            )} {totalRecords}`}
          />
        )}
      </FlexContainer>
    </>
  );
};
