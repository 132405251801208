import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, nested } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  CourseScheduleType,
  EnrollManyToManyFormValues,
  MaterialType,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { User, UserCourses } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { userStatusOptions } from '@/common/constants/enroll-types';
import { UserEnrollModal } from '@/components/courses/modals';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useUserCourses, useUsersSchedules } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { determineUserStatusColor } from '@/utils/helpers/ui.helper';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type UserDocumentsDatatableProps = {
  user: User;
};

export const UserDocumentsDatatable: React.FC<UserDocumentsDatatableProps> = ({
  user,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();

  const [showEnrollModal, setEnrollModal] = useState(false);
  const [loadingState, setLoadingState] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const {
    isLoading,
    userCourses: userDocuments,
    refetch,
  } = useUserCourses({
    accountId: account?.id,
    userId: user?.id,
    take,
    skip,
    filters: [
      ...apiFilters,
      group(GroupCondition.OR, [
        nested('course', [equal('type', MaterialType.CUSTOM_MATERIAL)]),
        nested('course', [equal('type', MaterialType.RESOURCE)]),
      ]),
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const columns: DataTableColumnType[] = [
    {
      field: 'course',
      header: t('materials.enrolled'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_COURSE_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.name.search'),
      },
      render: (row: UserCourses) => row?.course.name,
    },
    {
      field: 'enrollDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: false,
      render: (row: UserCourses) =>
        row?.enrollDate ? (
          <FormatDate date={row?.enrollDate} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'completeDate',
      header: t('reports.courses.completionDate'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) =>
        row?.attempt?.completed ? (
          <FormatDate date={row?.attempt?.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'enrollment.dueDate',
            header: t('generic.dueDate'),
            sortable: true,
            filterable: true,
            filters: {
              label: t('filter.overdueMaterials'),
              field: FilterNamesEnum.COURSE_ENTITY_USER_HAS_DUE_COURSES,
              type: FilterTypeEnum.SELECT_YES_NO,
              placeholder: t('generic.select'),
            },
            className: 'no-padding',
            render: (row: UserCourses) => (
              <>
                {row?.enrollment?.dueDate ? (
                  <FormatDate
                    format={DateFormats.TIMEDATE}
                    date={row.enrollment.dueDate}
                  />
                ) : (
                  <span>&#8212;</span>
                )}
              </>
            ),
          } as DataTableColumnType,
        ]
      : []),
    {
      field: 'status',
      header: t('generic.status'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.MULTI_SELECT,
        options: userStatusOptions(t),
        field: FilterNamesEnum.ENTITY_BY_STATUS,
      },
      render: (row: UserCourses) => determineUserStatusColor(row?.status, t),
    },
  ];

  const checkAndTrackHubspotEvent = () => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({
        [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
      });
    }
  };

  const usersSchedules = useUsersSchedules();
  const handleSubmitEnrollUsers = async (data: EnrollManyToManyFormValues) => {
    try {
      setLoadingState(LoadingStatuses.LOADING);

      await usersSchedules.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        users: data?.users?.map((user) => user.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: CourseScheduleType.STATIC,
      });

      setTimeout(() => {
        refetch();
        setLoadingState(LoadingStatuses.IDLE);
        setEnrollModal(false);
        toast?.success(t('toast.success'), t('courses.user.enroll.success'));
      }, 2000);

      checkAndTrackHubspotEvent();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['course'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
        />
        <AppButton
          label={t('generic.enroll')}
          aria-controls="overlay_menu"
          onClick={() => setEnrollModal(true)}
        />
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USER_MATERIALS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(
      RedirectPaths[
        e.data?.course?.type === MaterialType.RESOURCE
          ? RedirectPathsEnum.RESOURCES_EDIT
          : e.data?.course?.type === MaterialType.CUSTOM_MATERIAL
          ? RedirectPathsEnum.CUSTOM_MATERIALS_EDIT
          : RedirectPathsEnum.POSTER_EDIT
      ](e.data?.course?.id),
    );

  return (
    <>
      <DataTable
        dataKey="dataId"
        data={userDocuments?.result.map((x) => ({
          ...x,
          dataId: `${x.id}-${x.course?.id}`,
        }))}
        count={userDocuments?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
      <UserEnrollModal
        account={account as Account}
        visible={showEnrollModal}
        onSubmit={handleSubmitEnrollUsers}
        onHide={() => setEnrollModal(false)}
        selectedUser={user}
        loadingState={loadingState}
        isDocument
      />
    </>
  );
};
