import { GroupCondition } from '@/api/enums';
import { equal, group, lower } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  CourseEntityScheduleEnum,
  CourseScheduleType,
  EnrollManyToManyFormValues,
  MaterialType,
} from '@/client/courses';
import { User } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { manyToManyEnrollSchema } from '@/components/courses/validations/enroll';
import { CoursesMultiselectInput } from '@/components/form/selectors';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type ManyToManyEnrollModalProps = {
  onSubmit: (data: EnrollManyToManyFormValues) => void;
  account: Account;
  selectedUser: User;
  loadingState: LoadingStatuses;
  isDocument?: boolean;
} & DialogProps;

export const UserEnrollModal: React.FC<ManyToManyEnrollModalProps> = ({
  onSubmit,
  onHide,
  account,
  visible,
  selectedUser,
  loadingState,
  isDocument,
}) => {
  const { t } = useTranslation();
  const [initialValues] = useState<EnrollManyToManyFormValues>({
    courses: [],
    enrollTo: CourseEntityScheduleEnum.USER,
    users: [selectedUser],
    date: null,
    autoEnroll: false,
    type: CourseScheduleType.STATIC,
  });

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('generic.enroll')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="enroll-user"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={manyToManyEnrollSchema(t)}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form>
              <div className="field w-full mb-4">
                <label htmlFor="courses">
                  {`${isDocument ? t('materials') : t('courses')} (${
                    values?.courses?.length ?? 0
                  })`}
                </label>
                <CoursesMultiselectInput
                  className="w-full"
                  onChange={(courses) => {
                    setFieldValue('courses', courses);
                  }}
                  additionalFilters={[
                    equal('availableToAccounts', account?.id),
                    equal('retired', false),
                    group(GroupCondition.OR, [
                      lower('releaseDate', new Date(), true),
                      equal('releaseDate', null),
                    ]),
                    isDocument
                      ? group(GroupCondition.OR, [
                          equal('type', MaterialType.RESOURCE),
                          equal('type', MaterialType.CUSTOM_MATERIAL),
                        ])
                      : equal('type', MaterialType.COURSE),
                  ]}
                  selectedOptions={values.courses}
                  placeholder={isDocument ? t('materials.select') : undefined}
                />
              </div>

              <div className="field w-full mb-4">
                <label>{t('generic.enrollDate')}</label>
                <AppCalendar
                  id="enroll-date"
                  name="enrollDate"
                  className="w-full"
                  placeholder={t('courses.select.enrollDate')}
                  minDate={new Date()}
                  value={values.date}
                  onChange={(event: FormEvent) => {
                    setFieldValue(
                      'date',
                      moment(event.value?.toString()).utc(true).toDate(),
                    );
                  }}
                  readOnlyInput
                />
              </div>
              <Message
                severity="warn"
                text={t('courses.enroll.user.warning.name', {
                  name: selectedUser?.name,
                })}
              />
              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  data-testid="enroll-user-cancel-form"
                />
                <AppButton
                  label={t('generic.enroll')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  data-testid="enroll-user-submit-form"
                  state={loadingState}
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
