import { handleAxiosError } from '@/api/helpers';
import { CampaignFormValues } from '@/client/campaigns';
import { Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { CampaignFormErrors } from '@/components/campaigns/forms/CampaignFormErrors';
import { CampaignWizardSteps } from '@/components/campaigns/forms/CampaignWizardSteps';
import { SendTestEmail } from '@/components/campaigns/SendTestEmail';
import {
  launchCampaignSchema,
  saveCampaignTemplateSchema,
} from '@/components/campaigns/validations/campaigns.validation';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { Steps } from 'primereact/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CampaignFormProps = {
  initialValues: CampaignFormValues;
  title: string;
  onSubmit: (data: CampaignFormValues) => Promise<void>;
  onSave: (data: CampaignFormValues) => Promise<void>;
  isCopy?: boolean;
  campaignId?: string;
  launchState: typeof LoadingStatuses.IDLE | typeof LoadingStatuses.LOADING;
  saveState: LoadingStatuses;
  activeStep: number;
  onStepChange: (step: number) => void;
  isTemplate: boolean;
};

const steps = (t: TranslationFunctionType) => [
  {
    label: t('templates.email'),
  },
  {
    label: t('templates.page'),
  },
  {
    label: t('campaign.sender'),
  },
  {
    label: t('campaign.target'),
  },
  {
    label: t('campaign.period'),
  },
];

const templateSteps = (t: TranslationFunctionType) => [
  {
    label: t('templates.email'),
  },
  {
    label: t('templates.page'),
  },
  {
    label: t('campaign.sender'),
  },
  {
    label: t('campaign.template.info'),
  },
];

export const CampaignForm: React.FC<CampaignFormProps> = ({
  initialValues,
  title,
  onSubmit,
  onSave,
  campaignId,
  launchState,
  saveState,
  activeStep,
  onStepChange,
  isTemplate,
}) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();
  const { setDialogData } = useContext(DialogContext);
  const navigate = useNavigate();
  const toast = useToast();

  const [lastSavedData, setLastSavedData] = useState<
    CampaignFormValues | undefined
  >(undefined);
  const [exitPrompt, setExitPrompt] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setLastSavedData(initialValues);
  }, [initialValues]);

  const handleOnNextStep = async (values: CampaignFormValues) => {
    try {
      // If any changes were made, save on next step
      if (!exitPrompt) {
        await onSave(values);
        setLastSavedData(values);
        toast?.success(t('toast.success'), t('campaign.onStep.saved'));
      }
      onStepChange(activeStep + 1);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleOnPrevStep = async (values: CampaignFormValues) => {
    try {
      // If any changes were made, save on previous step
      if (!exitPrompt) {
        await onSave(values);
        setLastSavedData(values);
        toast?.success(t('toast.success'), t('campaign.onStep.saved'));
      }
      onStepChange(activeStep - 1);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSave = async (values: CampaignFormValues) => {
    await onSave(values);
    setLastSavedData(values);
  };
  const handleExit = () => {
    if (exitPrompt) {
      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]());
      return;
    }

    setDialogData({
      type: 'confirmation',
      show: true,
      header: t('dialog.unsavedChanges'),
      message: t('dialog.unsavedChanges.confirm'),
      onAccept: () => navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]()),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        isTemplate
          ? saveCampaignTemplateSchema(t)
          : launchCampaignSchema(
              t,
              canUseFeature(Subjects.PHISHING_CAMPAIGN_TARGET_SELECTOR) ??
                undefined,
            )
      }
    >
      {({ values, setFieldValue, errors }) => {
        // If the currentValues and lastSavedValues are the same, disable exit prompt
        useEffect(() => {
          setExitPrompt(
            isEqual(JSON.stringify(values), JSON.stringify(lastSavedData)),
          );
        }, [values, lastSavedData]);

        return (
          <Form
            id="campaign-form-container"
            data-testid="campaign-form-container"
          >
            <div id="campaign-header-container">
              <FlexContainer justify="space-between">
                <h1>{title}</h1>
                <SendTestEmail
                  campaignId={campaignId as string}
                  lastSavedData={{
                    emailTemplateId: values?.emailTemplate,
                    landingPageId: values?.landingPage,
                    sendingProfileId: values?.sendingProfile?.id,
                    encodeEmailTemplateImages:
                      values?.encodeEmailTemplateImages,
                  }}
                />
              </FlexContainer>
              <CampaignFormErrors errors={errors} showErrors={showErrors} />
              <Steps
                model={isTemplate ? templateSteps(t) : steps(t)}
                activeIndex={activeStep}
                onSelect={async (e) => {
                  try {
                    if (!exitPrompt) {
                      await handleSave(values);
                      toast?.success(
                        t('toast.success'),
                        t('campaign.onStep.saved'),
                      );
                    }
                    onStepChange(e.index);
                  } catch (e) {
                    handleAxiosError(e as Error | AxiosError, toast);
                  }
                }}
                readOnly={false}
                className="mb-4"
                data-testid="campaign-form-steps-navigation"
              />
            </div>
            <div
              id="camapign-content-container"
              data-testid="campaign-form-current-step-container"
            >
              <CampaignWizardSteps
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                activeStep={activeStep}
                campaignId={campaignId}
                exitPrompt={exitPrompt}
                isTemplate={isTemplate}
              />
            </div>

            <FlexContainer
              id="campaign-footer-container"
              justify="space-between"
              style={{ position: 'sticky', bottom: '-24px' }}
            >
              <div>
                <AppButton
                  type="outlined"
                  severity="secondary"
                  label={t('button.exit')}
                  className="mr-3"
                  onClick={handleExit}
                  isDisabled={
                    saveState === LoadingStatuses.LOADING ||
                    launchState === LoadingStatuses.LOADING
                  }
                />
                <AppButton
                  severity="secondary"
                  label={t('button.save')}
                  onClick={async () => {
                    try {
                      await handleSave(values);
                      toast?.success(t('toast.success'), t('campaign.saved'));
                    } catch (e) {
                      handleAxiosError(e as Error | AxiosError, toast);
                    }
                  }}
                  state={saveState}
                />
              </div>

              <div>
                {activeStep > 0 && (
                  <AppButton
                    severity="secondary"
                    label={t('button.back')}
                    icon="pi pi-arrow-left"
                    iconPos="left"
                    onClick={() => handleOnPrevStep(values)}
                    className="mr-3"
                    isDisabled={
                      saveState === LoadingStatuses.LOADING ||
                      launchState === LoadingStatuses.LOADING
                    }
                  />
                )}
                {activeStep < 4 && !isTemplate && (
                  <AppButton
                    severity="secondary"
                    label={t('button.next')}
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => handleOnNextStep(values)}
                    isDisabled={saveState === LoadingStatuses.LOADING}
                  />
                )}
                {activeStep < 3 && isTemplate && (
                  <AppButton
                    severity="secondary"
                    label={t('button.next')}
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => handleOnNextStep(values)}
                    isDisabled={saveState === LoadingStatuses.LOADING}
                  />
                )}
                {activeStep === 4 && (
                  <AppButton
                    isSubmit
                    label={t('campaign.launch')}
                    onClick={() => setShowErrors(true)}
                    state={launchState}
                  />
                )}
              </div>
            </FlexContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
