import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { AccountCard } from '@/components/accounts/AccountCard';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountHeader } from './AccountHeader';

const StyledCardsWrapper = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--default-padding);
  padding-bottom: var(--default-padding);
  flex-wrap: wrap;
`;

const getPathItems = (
  account: Account,
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }
  items.push({
    label: t('integrations'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id),
    template: AppBreadCrumbTemplate,
  });

  return items;
};

export const AccountIntegrationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { account, isLoading } = useAccount({ accountId: id });
  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {account && currentAccount && (
            <>
              <AppBreadCrumb model={getPathItems(account, currentAccount, t)} />
              <AccountHeader selectedAccount={account} />
            </>
          )}

          <StyledCardsWrapper>
            <AccountCard
              title={t('gophish')}
              content={t('gophish.integration.content')}
              footer={
                <AppButton
                  type="outlined"
                  label={
                    account?.meta?.goPhishURL && account?.meta?.goPhishPassword
                      ? t('generic.view')
                      : t('generic.connect')
                  }
                  className="w-full mt-auto"
                  onClick={() =>
                    navigate(
                      RedirectPaths[
                        RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_GO_PHISH
                      ](id as string),
                    )
                  }
                />
              }
            />

            <AccountCard
              title={t('hubspot')}
              content={t('hubspot.integration.content')}
              footer={
                <AppButton
                  type="outlined"
                  label={
                    account?.hubspot?.id
                      ? t('generic.view')
                      : t('generic.connect')
                  }
                  className="w-full mt-auto"
                  onClick={() =>
                    navigate(
                      RedirectPaths[
                        RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_HUBSPOT
                      ](id as string),
                    )
                  }
                />
              }
            />
          </StyledCardsWrapper>
        </>
      )}
    </>
  );
};
