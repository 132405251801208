import { client } from '@/client';
import { User } from '@/client/users';
import {
  REFRESH_TOKEN_EXPIRES_KEY,
  TOKEN_EXPIRES_KEY,
} from '@/common/constants/user';
import { AppDispatch, RootState } from '@/store/store';
import { PureAbility } from '@casl/ability';
import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

type CurrentUserState = {
  loading: boolean;
  loaded: boolean;
  user: User | null;
  error: string | null;
  ability:
    | {
        action: string;
        subject: string | string[];
      }[]
    | null; // eslint-disable-line,
  featureFlagsAbility:
    | {
        action: string;
        subject: string | string[];
      }[]
    | null; // eslint-disable-line,
};

const initialState: CurrentUserState = {
  user: null,
  loading: false,
  loaded: false,
  error: null,
  ability: null,
  featureFlagsAbility: null,
};

const currentUserSlice = createSlice({
  name: 'current-user',
  initialState,
  reducers: {
    setCurrentUserLoading: (
      state: Draft<CurrentUserState>,
      action: PayloadAction<boolean>,
    ) => {
      state.loading = action.payload;
    },
    setCurrentUserLoaded: (
      state: Draft<CurrentUserState>,
      action: PayloadAction<boolean>,
    ) => {
      state.loaded = action.payload;
    },
    setCurrentUser: (
      state: Draft<CurrentUserState>,
      action: PayloadAction<User>,
    ) => {
      state.user = action.payload;
      state.ability = action.payload.ability as {
        action: string;
        subject: string | string[];
      }[];
      state.featureFlagsAbility = action.payload.featureFlagsAbility as {
        action: string;
        subject: string | string[];
      }[];
    },
    setCurrentUserError: (
      state: Draft<CurrentUserState>,
      action: PayloadAction<string>,
    ) => {
      state.error = action.payload;
    },
    clearCurrentUser: (state: Draft<CurrentUserState>) => {
      state.user = null;
      state.ability = null;
      state.featureFlagsAbility = null;
    },
  },
});

export const removeCurrentUserAction = (dispatch: AppDispatch) => {
  dispatch(clearCurrentUser());

  sessionStorage.removeItem(TOKEN_EXPIRES_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_EXPIRES_KEY);
};

export const loadCurrentUserAction = async (dispatch: AppDispatch) => {
  dispatch(setCurrentUserLoading(true));

  try {
    const user = await client.default.getMe();

    dispatch(setCurrentUser(user));
    dispatch(setCurrentUserLoading(false));
    dispatch(setCurrentUserLoaded(true));
  } catch (e) {
    dispatch(setCurrentUserError((e as Error).message));
    dispatch(setCurrentUserLoaded(true));
  }
};

export const selectCurrentUser = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) => currentUserState.user,
);

export const selectCurrentUserAbility = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) =>
    currentUserState.user
      ? new PureAbility(
          currentUserState.ability as {
            action: string;
            subject: string | string[];
          }[],
        )
      : undefined,
);

export const selectCurrentUserFeatureAbility = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) =>
    currentUserState.user
      ? new PureAbility(
          currentUserState.featureFlagsAbility as {
            action: string;
            subject: string | string[];
          }[],
        )
      : undefined,
);

export const selectCurrentUserIsLoading = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) => currentUserState.loading,
);

export const selectCurrentUserLoaded = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) => currentUserState.loaded,
);

export const selectCurrentUserState = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) => currentUserState,
);

export const selectCurrentUserError = createSelector(
  (state: RootState) => state.currentUser,
  (currentUserState) => currentUserState.error,
);

export const {
  setCurrentUserLoading,
  setCurrentUser,
  setCurrentUserError,
  setCurrentUserLoaded,
  clearCurrentUser,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
