import { FiltersType } from '@/api/types';
import { client } from '@/client';
import {
  AccountFeatureFlag,
  ListFeatureFlagResponse,
} from '@/client/feature-flags/types';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

const queryKey = 'features';

export const useFeatureFlags = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListFeatureFlagResponse> & {
  flags: ListFeatureFlagResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.featureFlags.getFeatureFlags(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    flags: data,
    ...(rest as UseQueryResult<ListFeatureFlagResponse>),
  };
};

export const useAccountsFeatureFlags = (
  accountId: string | undefined,
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<AccountFeatureFlag[]> & {
  flags: AccountFeatureFlag[] | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.featureFlags.getAccountFeatureFlags(accountId, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    flags: data,
    ...(rest as UseQueryResult<AccountFeatureFlag[]>),
  };
};
